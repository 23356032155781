import { useState, useEffect, useCallback } from "react";

import ConfirmationModal from "../../../components/modals/confirmation-modal/ConfirmationModal";
import SubcontractorsSection from "./SubcontractorsSection";
import TimeMaterialsSection from "./TimeMaterialsSection";
import MaterialsSection from "./MaterialsSection";
import IncidentsSection from "./IncidentsSection";
import InjuriesSection from "./InjuriesSection";
import IssuesSection from "./IssuesSection";

import { ParCheckbox } from "../../../components/helpers/ParFormComponents";
import { useCurrentPar } from "../../../components/contexts/CurrentParContext";
import { useModalContext } from "../../../components/contexts/ModalContext";

const SiteActivityCheckboxSection = (props) => {
  const { setBottomPhaseAdd } = props;
  const [checkboxType, setCheckboxType] = useState("");

  const { parFormDataState, parDispatch } = useCurrentPar();
  const { handleCloseModal, handleOpenModal, isModalOpen, modalDescription } =
    useModalContext();

  const { issues, idb, subcontractor, material, checkboxes } = parFormDataState;

  const confirmationModalInfo = {
    addAdditionalIssue: {
      modalQuestion: "Would you like to add another Issues record?",
      onConfirm: () => handleAddAdditionalIssue(),
    },
    verifyNoIssues: {
      modalQuestion: "Are you sure there were no Issues with this Par?",
      onConfirm: () => handleConfirmNoIssues(),
    },
  };

  const checkboxItems = [
    {
      checked: checkboxes.issues,
      labelName: "Issues?",
      onChange: handleIdbCheckbox,
      disabled: idb?.length > 0 && checkboxes.issues,
    },
    {
      checked: checkboxes.incidents,
      labelName: "Incidents?",
      onChange: () => handleCheckmarkChange("incidents", checkboxes),
      disabled: false,
    },
    {
      checked: checkboxes.injuries,
      labelName: "Injuries?",
      onChange: () => handleCheckmarkChange("injuries", checkboxes),
      disabled: false,
    },
    {
      checked: checkboxes.subcontractor,
      labelName: "Subcontractors?",
      onChange: handleSubcontractorCheckbox,
      disabled: subcontractor[0]?.id !== 0 && subcontractor.length > 0,
    },
    {
      checked: checkboxes.timeMaterials,
      labelName: "Time & Material?",
      onChange: handleTimeMaterialCheckbox,
      disabled: isTimeMaterialCheckboxDisabled(),
    },
    {
      checked: checkboxes.material,
      labelName: "Materials?",
      onChange: handleMaterialCheckbox,
      disabled: material?.length > 0 && material[0]?.id !== 0,
    },
  ];

  const basicFieldUpdate = useCallback(
    (value, field, type) => {
      parDispatch({
        type,
        payload: {
          field,
          value,
        },
      });
    },
    [parDispatch]
  );

  function handleIdbCheckbox() {
    if (checkboxes.issues) {
      setCheckboxType("issues");
      handleOpenModal("verifyNoIssues");
    } else {
      parDispatch({
        type: "updateCheckbox",
        payload: {
          field: "issues",
          value: true,
        },
      });

      basicFieldUpdate([], "idb", "updateInputField");
    }
  }

  const handleConfirmNoIssues = () => {
    if (checkboxType === "issues") {
      const emptyIssue = {
        issueNumber: 0,
        issueType: "No Issues Entered",
        description: "",
        dateRaised: "",
        raisedBy: "",
      };

      basicFieldUpdate([emptyIssue], "idb", "updateInputField");

      parDispatch({
        type: "updateCheckbox",
        payload: {
          field: "issues",
          value: false,
        },
      });
    }

    handleCloseModal("verifyNoIssues");
  };

  const handleAddAdditionalIssue = () => {
    handleCloseModal("addAdditionalIssue");
    handleOpenModal("addIssue");
  };

  const handleCheckmarkChange = (type, allCheckboxValues) => {
    if (type === "incidents" || "injuries") {
      const titledType = type.charAt(0).toUpperCase() + type.slice(1);
      const checkmarkLookup = allCheckboxValues[type] ? "noReport" : "report";
      const checkmarkBoolean = {
        report: `One or more ${titledType} were reported in accordance with SOP's.`,
        noReport: `Nothing to Report.`,
      };
      basicFieldUpdate(
        checkmarkBoolean[checkmarkLookup],
        type,
        "updateInputField"
      );
    } else if (type === "issues" && allCheckboxValues[type] === false) {
      basicFieldUpdate("", type, "updateInputField");
    }

    parDispatch({
      type: "updateCheckbox",
      payload: {
        field: [type],
        value: !checkboxes[type],
      },
    });
  };

  function handleSubcontractorCheckbox() {
    if (!checkboxes.subcontractor) {
      basicFieldUpdate([], "subcontractor", "updateInputField");
    }
    if (subcontractor.length === 0 && checkboxes.subcontractor) {
      const emptySubcontractor = {
        id: 0,
        name: "none",
        description: "None of our subcontractors were observed this period.",
        dailyHours: 0,
        employeeCount: 0,
        type: "none",
      };
      basicFieldUpdate(
        [emptySubcontractor],
        "subcontractor",
        "updateInputField"
      );
    }

    parDispatch({
      type: "updateCheckbox",
      payload: {
        field: "subcontractor",
        value: !checkboxes.subcontractor,
      },
    });
  }

  function isTimeMaterialCheckboxDisabled() {
    return !(
      parFormDataState.matCustomerCode === "" &&
      parFormDataState.matOsr === "" &&
      parFormDataState.matJobsite === ""
    );
  }

  function handleTimeMaterialCheckbox() {
    if (checkboxes.timeMaterials) {
      basicFieldUpdate("", "matCustomer", "updateInputField");
      basicFieldUpdate("", "matCustomerCode", "updateInputField");
      basicFieldUpdate("", "matOsr", "updateInputField");
      basicFieldUpdate("", "matJobsite", "updateInputField");
    }

    parDispatch({
      type: "updateCheckbox",
      payload: {
        field: "timeMaterials",
        value: !checkboxes.timeMaterials,
      },
    });
  }

  function handleMaterialCheckbox() {
    if (!checkboxes.material) {
      const emptyMaterial = {
        id: 0,
        phaseCode: "",
        phaseDesc: "",
        description: "None of our materials were observed this period.",
        units: "0.00",
        cost: "0.00",
        total: "0.00",
      };

      basicFieldUpdate([emptyMaterial], "material", "updateInputField");
    } else if (checkboxes.material) {
      basicFieldUpdate([], "material", "updateInputField");
    }

    parDispatch({
      type: "updateCheckbox",
      payload: {
        field: "material",
        value: !checkboxes.material,
      },
    });
  }

  useEffect(() => {
    if (!idb) {
      if (issues !== "Nothing to Report." && issues.length > 0) {
        parDispatch({
          type: "addDeprecatedIdb",
          payload: issues,
        });
      }
    } else if (
      idb.length === 0 &&
      issues !== "Nothing to Report." &&
      issues.length > 0
    ) {
      parDispatch({
        type: "addDeprecatedIdb",
        payload: issues,
      });
    }
  }, [idb, issues, parDispatch]);

  return (
    <>
      <div className="par-checkbox-container">
        <div className="checkbox-item">
          {checkboxItems.map((checkbox) => {
            return (
              <ParCheckbox
                key={checkbox.labelName}
                checked={checkbox.checked}
                labelName={checkbox.labelName}
                onChange={checkbox.onChange}
                disabled={checkbox.disabled}
              />
            );
          })}
        </div>
      </div>

      <div className="problems-holder">
        <IssuesSection />

        <IncidentsSection />

        <InjuriesSection />

        <SubcontractorsSection />

        <TimeMaterialsSection />

        <MaterialsSection setBottomPhaseAdd={setBottomPhaseAdd} />
      </div>

      {Object.keys(confirmationModalInfo).includes(modalDescription) && (
        <ConfirmationModal
          isModalOpen={isModalOpen[modalDescription]}
          onRequestClose={() => handleCloseModal(modalDescription)}
          onConfirm={confirmationModalInfo[modalDescription].onConfirm}
          modalQuestion={confirmationModalInfo[modalDescription].modalQuestion}
        />
      )}
    </>
  );
};

export default SiteActivityCheckboxSection;
