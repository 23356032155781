import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import VerificationPhasecode from "../../components/VerificationPhasecode";
import VerificationTotals from "../../components/VerificationTotals";
import PrintEmailForm from "../../components/forms/PrintEmailForm";
import ApproverChanges from "../../components/ApproverChanges";
import Modal from "../../components/modals/Modal";
import { useCurrentPar } from "../../components/contexts/CurrentParContext";
import { baseParFormData } from "../../components/reducer/store/baseParFormData";
import { useModalContext } from "../../components/contexts/ModalContext";

import useAbortEffect from "../../components/hooks/useAbortEffect";
import useApprovalFunctions from "./hooks/useApprovalFunctions";

import Truncate from "../../components/helpers/Truncate";
import asyncAPICall from "../../util/apiWrapper";
import { ParTextArea } from "../../components/helpers/ParFormComponents";
import { todaysFormattedDate } from "../../util/dayString";

const VerificationPage = (props) => {
  const { parFormDataState } = useCurrentPar();
  const { pathname } = useLocation();
  const [parData, setParData] = useState(baseParFormData);
  const [hideButton, setHideButton] = useState(false);
  const [disableRejectButton, setDisableRejectButton] = useState(false);
  const [sendEmail, setSendEmail] = useState("");
  const [foreman, setForeman] = useState("");
  const [pfs, setPfs] = useState("");
  const [jobNumDesc, setJobNumDesc] = useState("");
  const [approverEmail, setApproverEmail] = useState("");
  const [rejectMessage, setRejectMessage] = useState("");
  const [submitterEmail, setSubmitterEmail] = useState("");
  const [combinedCheckboxValues, setCombinedCheckboxValues] = useState([]);
  const [phaseCheckmarkValues, setPhaseCheckmarkValues] = useState({});
  const [checkboxValues, setCheckboxValues] = useState({
    idb: false,
    jobInfo: false,
    problems: false,
    subcontractor: false,
    material: false,
    timeMaterials: false,
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const history = useHistory();
  const splitDate = parData.date.split("/");
  const formattedDate = `20${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`;
  const dcrNumber = parData ? parData.dcrNumber : parFormDataState.dcrNumber;
  const pathRef = window.location.href;
  const pathRefArray = pathRef.split("/", 3);
  const dynamicPath = pathRefArray.join("/");
  const emailLink = `${dynamicPath}/par+${dcrNumber}`;
  const { isModalOpen, handleOpenModal, handleCloseModal } = useModalContext();

  const { submitFullPar, parApprovalStatus } = useApprovalFunctions();
  const slug = pathname.split("/");
  const view = slug[2];
  const viewId = slug[3];

  const changeDisabledSubmit = useCallback((updateField, index) => {
    setCombinedCheckboxValues((prevCheckboxValue) => {
      return prevCheckboxValue.map((checkboxObj) => {
        if (checkboxObj.index === index) {
          return { ...checkboxObj, [updateField]: !checkboxObj[updateField] };
        } else {
          return checkboxObj;
        }
      });
    });
  }, []);

  const allBoxesChecked = useMemo(() => {
    for (let checkboxValue of Object.values(checkboxValues)) {
      if (!checkboxValue) return false;
    }

    for (let checkboxValue of Object.values(phaseCheckmarkValues)) {
      if (!checkboxValue) {
        return false;
      }
    }

    return true;
  }, [phaseCheckmarkValues, checkboxValues]);

  const renderPhaseTables = useMemo(() => {
    const checkboxList = [];

    const movePerDiem = Object.keys(parData.phaseCodes).filter(
      (phaseCode) => phaseCode !== "012130-00-00"
    );

    const perdiemExists = Object.keys(parData.phaseCodes).some(
      (phaseCode) => phaseCode === "012130-00-00"
    );

    if (perdiemExists) movePerDiem.push("012130-00-00");

    for (let index in movePerDiem) {
      if (movePerDiem[index] === "012130-00-00") {
        checkboxList.push({
          index: Number(index),
          phase: false,
        });
      } else {
        checkboxList.push({
          index: Number(index),
          phase: false,
        });
      }
    }

    setCombinedCheckboxValues(checkboxList);

    return movePerDiem.map((phaseNum, index) => {
      return (
        <div key={phaseNum}>
          <VerificationPhasecode
            key={phaseNum}
            index={index}
            phaseNum={phaseNum}
            parData={parData}
            view={view}
            changeDisabledSubmit={changeDisabledSubmit}
            phaseCheckmarkValues={phaseCheckmarkValues}
            setPhaseCheckmarkValues={setPhaseCheckmarkValues}
            windowWidth={windowWidth}
          />

          {index !== movePerDiem.length - 1 && (
            <div className="break-wrapper">
              <div className="checkbox" />
              <hr />
            </div>
          )}
        </div>
      );
    });
  }, [changeDisabledSubmit, parData, view, phaseCheckmarkValues, windowWidth]);

  const renderField = (categories, id, renderCell) => {
    return categories.map((category, idx) => (
      <td className={idx === 0 ? "first-column" : ""} key={category[id]}>
        {renderCell(category)}
      </td>
    ));
  };

  const renderSubcontractors = () => {
    if (windowWidth <= 650) {
      return (
        <div className="table-wrapper">
          {parData.subcontractor[0]?.name === "none" ? (
            <table>
              <tbody>
                <tr>
                  <th className="vertical-header">
                    <span>Subcontractors</span>
                  </th>
                  <td className="first-column">
                    None of our subcontractors were observed this period.
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table>
              <tbody>
                <tr>
                  <th className="vertical-header">
                    <span>Subcontractor</span>
                  </th>
                  {renderField(
                    parData.subcontractor,
                    "id",
                    (subcontractor) => subcontractor.name
                  )}
                </tr>
                <tr>
                  <th className="vertical-header">
                    <span>Description</span>
                  </th>
                  {renderField(
                    parData.subcontractor,
                    "id",
                    (subcontractor) => subcontractor.description
                  )}
                </tr>
                <tr>
                  <th className="vertical-header">
                    <span>Hours</span>
                  </th>
                  {renderField(
                    parData.subcontractor,
                    "id",
                    (subcontractor) => subcontractor.dailyHours
                  )}
                </tr>
                <tr>
                  <th className="vertical-header">
                    <span>Count</span>
                  </th>
                  {renderField(
                    parData.subcontractor,
                    "id",
                    (subcontractor) => subcontractor.employeeCount
                  )}
                </tr>
              </tbody>
            </table>
          )}
        </div>
      );
    } else {
      return (
        <div className="table-wrapper">
          {parData.subcontractor[0]?.name === "none" ? (
            <table>
              <thead>
                <tr>
                  <th>Subcontractors</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>None of our subcontractors were observed this period.</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Subcontractor</th>
                  <th>Description</th>
                  <th>Hours</th>
                  <th>Count</th>
                </tr>
              </thead>

              <tbody>
                {parData.subcontractor.map((subcontractor) => {
                  return (
                    <tr key={subcontractor.id}>
                      <td>{subcontractor.name}</td>
                      <td>{subcontractor.description}</td>
                      <td>{subcontractor.dailyHours}</td>
                      <td>{subcontractor.employeeCount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      );
    }
  };

  const renderTimeMaterials = () => {
    if (windowWidth <= 650) {
      return (
        <div className="table-wrapper">
          {parData.matCustomer === "" ? (
            <table>
              <tbody>
                <tr>
                  <th className="vertical-header">
                    <span>T&M Customer</span>
                  </th>
                  <td className="first-column">
                    None of our time and material customers were observed this
                    period.
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table>
              <tbody>
                <tr>
                  <th className="vertical-header">
                    <span>T&M Customer</span>
                  </th>
                  <td className="first-column">{parData.matCustomer}</td>
                </tr>
                <tr>
                  <th className="vertical-header">
                    <span>Customer Code</span>
                  </th>
                  <td className="first-column">{parData.matCustomerCode}</td>
                </tr>
                <tr>
                  <th className="vertical-header">
                    <span>Onsite Representative</span>
                  </th>
                  <td className="first-column">{parData.matOsr}</td>
                </tr>
                <tr>
                  <th className="vertical-header">
                    <span>Jobsite</span>
                  </th>
                  <td className="first-column">{parData.matJobsite}</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      );
    } else {
      return (
        <div className="table-wrapper">
          {parData.matCustomer === "" ? (
            <table>
              <thead>
                <tr>
                  <th>T&M Customer</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    None of our time and material customers were observed this
                    period.
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>T&M Customer</th>
                  <th>Customer Code</th>
                  <th>Onsite Representative</th>
                  <th>Jobsite</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{parData.matCustomer}</td>
                  <td>{parData.matCustomerCode}</td>
                  <td>{parData.matOsr}</td>
                  <td>{parData.matJobsite}</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      );
    }
  };

  const renderMaterials = () => {
    if (windowWidth <= 650) {
      return (
        <div className="table-wrapper">
          {parData.material[0]?.phaseCode === "" ? (
            <table>
              <tbody>
                <tr>
                  <th className="vertical-header">
                    <span>Materials</span>
                  </th>
                  <td className="first-column">
                    None of our materials were observed this period.
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table>
              <tbody>
                <tr>
                  <th className="vertical-header">
                    <span>Material</span>
                  </th>
                  {renderField(
                    parData.material,
                    "id",
                    (material) => material.description
                  )}
                </tr>
                <tr>
                  <th className="vertical-header">
                    <span>Units</span>
                  </th>
                  {renderField(parData.material, "id", (material) => {
                    return `${material.units} ${material.uom}`;
                  })}
                </tr>
                <tr>
                  <th className="vertical-header">
                    <span>Cost</span>
                  </th>
                  {renderField(
                    parData.material,
                    "id",
                    (material) => `$${material.cost}`
                  )}
                </tr>
                <tr>
                  <th className="vertical-header">
                    <span>Total</span>
                  </th>
                  {renderField(
                    parData.material,
                    "id",
                    (material) => `$${material.total}`
                  )}
                </tr>
                <tr>
                  <th className="vertical-header">
                    <span>Phase Code</span>
                  </th>
                  {renderField(
                    parData.material,
                    "id",
                    (material) => material.phaseCode
                  )}
                </tr>
                <tr>
                  <th className="vertical-header">
                    <span>Phase Description</span>
                  </th>
                  {renderField(
                    parData.material,
                    "id",
                    (material) => material.phaseDesc
                  )}
                </tr>
              </tbody>
            </table>
          )}
        </div>
      );
    } else {
      return (
        <div className="table-wrapper">
          {parData.material[0]?.phaseCode === "" ? (
            <table>
              <thead>
                <tr>
                  <th>Materials</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>None of our materials were observed this period.</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Material</th>
                  <th>Units</th>
                  <th>Cost</th>
                  <th>Total</th>
                  <th>Phase Code</th>
                  <th>Phase Description</th>
                </tr>
              </thead>

              <tbody>
                {parData.material.map((material) => {
                  return (
                    <tr key={material.id}>
                      <td>{material.description}</td>
                      <td>
                        {material.units} {material.uom}
                      </td>
                      <td>${material.cost}</td>
                      <td>${material.total}</td>
                      <td>{material.phaseCode}</td>
                      <td>{material.phaseDesc}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      );
    }
  };

  const renderIssues = () => {
    if (windowWidth <= 650) {
      return (
        <div className="table-wrapper">
          {parData.idb[0]?.issueType === "No Issues Entered" ? (
            <table>
              <tbody>
                <tr>
                  <th className="vertical-header">
                    <span>Issues</span>
                  </th>
                  <td className="first-column">No Issues Entered</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table>
              <tbody>
                <tr>
                  <th className="vertical-header">
                    <span>Issue Type</span>
                  </th>
                  {renderField(
                    parData.idb,
                    "issueNumber",
                    (issue) => issue.issueType
                  )}
                </tr>
                <tr>
                  <th className="vertical-header">
                    <span>Date</span>
                  </th>
                  {renderField(
                    parData.idb,
                    "issueNumber",
                    (issue) => issue.dateRaised
                  )}
                </tr>
                <tr>
                  <th className="vertical-header">
                    <span>Description</span>
                  </th>
                  {renderField(
                    parData.idb,
                    "issueNumber",
                    (issue) => issue.description
                  )}
                </tr>
              </tbody>
            </table>
          )}
        </div>
      );
    } else {
      return (
        <div className="table-wrapper">
          {parData.idb[0]?.issueType === "No Issues Entered" ? (
            <table>
              <thead>
                <tr>
                  <th>Issues</th>
                </tr>
              </thead>
              <tbody>
                <tr className="issues-rows">
                  <td>No Issues Entered</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Issue Type</th>
                  <th>Date</th>
                  <th>Description</th>
                </tr>
              </thead>

              <tbody>
                {parData.idb.map((issue) => {
                  return (
                    <tr className="issues-rows" key={issue.issueNumber}>
                      <td className="issue-type">{issue.issueType}</td>
                      <td className="date-raised">{issue.dateRaised}</td>
                      <td>{issue.description}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      );
    }
  };

  const handleParSubmit = () => {
    if (view === "par") {
      submitFullPar(
        parData,
        approverEmail,
        setHideButton,
        foreman,
        pfs,
        sendEmail
      );
    } else {
      parApprovalStatus("Approved", parData, setHideButton);
    }
  };

  const sendRejectEmail = () => {
    asyncAPICall(
      `api:5e9BgwVw/sendgrid/dynamicEmail`,
      "POST",
      {
        to_email: `${submitterEmail}`,
        template_id: "d-715298ff918245fcaf79789a361c4d27",
        data: {
          jobNumber: parData.jobNumber,
          date: parData.date,
          rejectMessage: `${rejectMessage}`,
          link: emailLink,
        },
      },
      null,
      () => history.push("/approvals"),
      (err) => {
        console.error("Sending Rejection Email Error: ", err);
      },
      null,
      true
    );
  };

  const handleRejectMessage = () => {
    const copyParData = { ...parData };
    copyParData.statusComments = [
      ...copyParData.statusComments,
      {
        id: uuidv4(),
        date: todaysFormattedDate(),
        status: "Reject",
        message: rejectMessage,
      },
    ];
    sendRejectEmail();
    parApprovalStatus("Reject", copyParData, setDisableRejectButton);
  };

  useAbortEffect(
    (signal) => {
      const setView = new Promise((res, rej) => {
        if (viewId) {
          asyncAPICall(
            `api:W2oHkD04/partblmain/parNumber?parNumber=${viewId}`,
            "GET",
            null,
            null,
            (data) => {
              setParData(data);
              res(data);
            },
            (err) => {
              if (!signal.aborted) {
                console.error("Error Getting PAR Information", err);
                rej(err);
              }
            },
            signal
          );
        } else {
          if (view !== "confirm") {
            setParData(parFormDataState);
            res(parFormDataState);
          }
        }
      });

      setView.then((viewedPar) => {
        if (props.history.location.state?.approvalsCheckmarks) {
          setCombinedCheckboxValues(
            props.history.location.state.approvalsCheckmarks
              .combinedCheckboxValues
          );

          setPhaseCheckmarkValues(
            props.history.location.state.approvalsCheckmarks
              .phaseCheckmarkValues
          );

          const prevCheckboxValues =
            props.history.location.state.approvalsCheckmarks.checkboxValues;

          setCheckboxValues(prevCheckboxValues);
        } else {
          const buildEmptyChecks = {};

          if (view !== "confirm") {
            Object.keys(parFormDataState.phaseCodes).forEach((phase) => {
              buildEmptyChecks[phase] = false;
            });
          } else {
            Object.keys(viewedPar.phaseCodes).forEach((phase) => {
              buildEmptyChecks[phase] = false;
            });
          }

          setPhaseCheckmarkValues(buildEmptyChecks);
        }
      });
    },
    [
      viewId,
      parFormDataState,
      props.history.location.state?.approvalsCheckmarks,
      parFormDataState.phaseCodes,
      view,
    ]
  );

  useAbortEffect(
    (signal) => {
      if (parData.jobNumber) {
        const getForemanPromise = new Promise((resolve, reject) => {
          //foreman 1111 does not currently exist
          if (parData.foreman !== 1111) {
            asyncAPICall(
              `api:KE7MCNYf/vEmployeesNoSpacesIdByID?employeeID=${parData.foreman}`,
              "GET",
              null,
              null,
              (data) => {
                if (data) {
                  const fullName = `${data[0].FirstName} ${data[0].MidName} ${data[0].LastName}`;
                  setForeman(fullName);
                  resolve(true);
                }
              },
              (err) => {
                if (!signal.aborted) {
                  reject("error getting Foreman", err);
                }
              },

              signal
            );
          }
        });

        const getPfsPromise = new Promise((resolve, reject) => {
          //foreman 1111 does not currently exist
          if (parData.foreman !== 1111) {
            asyncAPICall(
              `api:KE7MCNYf/vEmployeesNoSpacesIdByID?employeeID=${parData.pfs}`,
              "GET",
              null,
              null,
              (data) => {
                if (data) {
                  const fullName = `${data[0].FirstName} ${data[0].MidName} ${data[0].LastName}`;
                  setPfs(fullName);
                  setApproverEmail(data[0].Email);
                  resolve(true);
                }
              },
              (err) => {
                if (!signal.aborted) {
                  reject("error getting PFS", err);
                }
              },
              signal
            );
          }
        });

        const getParJobPromise = new Promise((resolve, reject) => {
          //foreman 1111 does not currently exist
          if (parData.foreman !== 1111) {
            asyncAPICall(
              `api:KE7MCNYf/vprojectswithid_by_Project?project=${parData.jobNumber}`,
              "GET",
              null,
              null,
              (data) => {
                if (data) {
                  const jobNameDescription = `${data[0].Project} ${data[0].Project_Name}`;
                  setJobNumDesc(jobNameDescription);
                  resolve(true);
                }
              },
              (err) => {
                if (!signal.aborted) {
                  reject("Getting Jobs Error", err);
                }
              }
            );
          }
        });

        Promise.all([getForemanPromise, getPfsPromise, getParJobPromise]).catch(
          console.error
        );
      }
    },
    [parData]
  );

  useAbortEffect(
    (signal) => {
      if (
        parData !== undefined &&
        parData.foreman !== 1111 &&
        parData.foreman
      ) {
        asyncAPICall(
          `api:KE7MCNYf/vEmployeesNoSpacesIdByID?employeeID=${parData.foreman}`,
          "GET",
          null,
          null,
          (data) => {
            if (data) {
              setSubmitterEmail(data[0].Email);
            }
          },
          (err) => {
            if (!signal.aborted) {
              console.error("Getting Submitter Email Error", err);
            }
          },
          signal
        );
      }
    },
    [parData]
  );

  useEffect(() => window.scrollTo(0, 0), []);

  const handleWindowResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  return (
    <div className="verification-page-container approval-verification">
      <div className="header-wrapper">
        <div className="break-wrapper">
          <div className="checkbox" />
        </div>

        <div className="header">
          <div className="top-header">
            CHECK EACH SECTION CAREFULLY TO MAKE <br />
            SURE ALL INFORMATION IS CORRECT
          </div>

          <div className="section-two-header">
            PROJECT ACTIVITY RECORD VERIFICATION
          </div>
        </div>
      </div>

      <div className="break-wrapper">
        <div className="checkbox" />
        <hr />
      </div>

      <div className="section-one">
        <label className="checkbox container">
          {view !== "approved" && view !== "searched" && (
            <>
              <input
                className="checkbox"
                type="checkbox"
                checked={checkboxValues.jobInfo}
                onChange={() =>
                  setCheckboxValues((c) => {
                    return { ...c, jobInfo: !c.jobInfo };
                  })
                }
              />
              <span className="checkmark job-info-checkbox" />
            </>
          )}
        </label>

        <div className="section-content">
          <div className="upper-content">
            <div className="section-grid">
              <div className="grid-row">
                <div className="data-label">Date: </div>
                <div className="data">
                  {parData.date.length > 0 ? parData.date : "--"}
                </div>
              </div>

              <div className="grid-row">
                <div className="data-label">Temp High: </div>
                <div className="data">
                  {parData.temperatureHigh.length > 0
                    ? `${parData.temperatureHigh}\xB0`
                    : "--"}
                </div>
              </div>

              <div className="grid-row">
                <div className="data-label">Temp Low: </div>
                <div className="data">
                  {parData.temperatureLow.length > 0
                    ? `${parData.temperatureLow}\xB0`
                    : "--"}
                </div>
              </div>

              <div className="grid-row">
                <div className="data-label">Precipitation: </div>
                <div className="data">
                  {parData.precipitation.length > 0
                    ? parData.precipitation
                    : "--"}
                </div>
              </div>

              <div className="grid-row">
                <div className="data-label">Wind: </div>
                <div className="data">
                  {parData.wind.length > 0 ? parData.wind : "--"}
                </div>
              </div>
            </div>

            <div className="section-grid">
              <div className="grid-row">
                <div className="data-label">Start Time: </div>
                <div className="data">
                  {parData?.startTime.length > 0 ? parData.startTime : "--"}
                </div>
              </div>

              <div className="grid-row">
                <div className="data-label">Stop Time: </div>
                <div className="data">
                  {parData?.stopTime.length > 0 ? parData.stopTime : "--"}
                </div>
              </div>

              <div className="grid-row">
                <div className="data-label">Approver: </div>
                <div className="data">{pfs !== "" ? pfs : "--"}</div>
              </div>

              <div className="grid-row">
                <div className="data-label">Submitter: </div>
                <div className="data">{foreman !== "" ? foreman : "--"}</div>
              </div>

              <div className="grid-row">
                <div className="data-label">Safety Topic: </div>
                <div className="data">
                  <Truncate
                    content={
                      parData.safetyTopic.length > 0
                        ? parData.safetyTopic
                        : "--"
                    }
                    characters={30}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="lower-content">
            <div className="section-grid">
              <div className="grid-row">
                <div className="data-label">Job Number: </div>
                <div className="data">
                  {parData.jobNumber.length > 0 ? jobNumDesc : "--"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="break-wrapper">
        <div className="checkbox" />
        <hr />
      </div>

      <div className="general-notes">
        <div className="break-wrapper">
          <div className="checkbox" />
        </div>

        <div className="section-content">
          <div className="label">PFS Daily Journal</div>
          <div className="details">
            {parData.notes?.length > 0 ? parData.notes : "--"}
          </div>
        </div>
      </div>

      <div className="general-notes">
        <div className="break-wrapper">
          <div className="checkbox" />
        </div>

        <div className="section-content">
          <div className="label daily-journal">Foreman Daily Journal</div>
          <div className="details">
            {parData.workPreformed?.length > 0 ? parData.workPreformed : "--"}
          </div>
        </div>
      </div>

      <div className="general-notes">
        <div className="break-wrapper">
          <div className="checkbox" />
        </div>

        <div className="section-content">
          <div className="label">Foreman Forecasted Work</div>
          <div className="details">
            {parData.workForecasted?.length > 0 ? parData.workForecasted : "--"}
          </div>
        </div>
      </div>

      <div className="break-wrapper">
        <div className="checkbox" />
        <hr />
      </div>

      <div className="section-wrapper">
        <label className="checkbox container">
          {view !== "approved" && view !== "searched" && (
            <>
              <input
                className="checkbox"
                checked={checkboxValues.idb}
                type="checkbox"
                onChange={() =>
                  setCheckboxValues((c) => {
                    return { ...c, idb: !c.idb };
                  })
                }
              />
              <span className="checkmark" />
            </>
          )}
        </label>

        {renderIssues()}
      </div>

      <div className="break-wrapper">
        <div className="checkbox" />
      </div>

      <div className="break-wrapper">
        <div className="checkbox" />
        <hr />
      </div>

      <div className="section-two">
        <label className="checkbox container">
          {view !== "approved" && view !== "searched" && (
            <>
              <input
                className="checkbox"
                type="checkbox"
                checked={checkboxValues.problems}
                onChange={() =>
                  setCheckboxValues((c) => {
                    return { ...c, problems: !c.problems };
                  })
                }
              />
              <span className="checkmark" />
            </>
          )}
        </label>

        <div className="section-content">
          <div className="group">
            <div className="label">Injuries</div>
            <div className="details">
              {parData.injuries.length > 0 ? parData.injuries : "--"}
            </div>
          </div>

          <div className="group">
            <div className="label">Incidents</div>
            <div className="details">
              {parData.incidents.length > 0 ? parData.incidents : "--"}
            </div>
          </div>
        </div>
      </div>

      <div className="break-wrapper">
        <div className="checkbox" />
        <hr />
      </div>

      <div className="section-wrapper">
        <label className="checkbox container">
          {view !== "approved" && view !== "searched" && (
            <>
              <input
                className="checkbox"
                type="checkbox"
                checked={checkboxValues.subcontractor}
                onChange={() =>
                  setCheckboxValues((c) => {
                    return { ...c, subcontractor: !c.subcontractor };
                  })
                }
              />
              <span className="checkmark" />
            </>
          )}
        </label>

        {parData.subcontractor.length > 0 ? renderSubcontractors() : "--"}
      </div>

      <div className="break-wrapper">
        <div className="checkbox" />
      </div>

      <div className="section-wrapper">
        <label className="checkbox container">
          {view !== "approved" && view !== "searched" && (
            <>
              <input
                className="checkbox"
                type="checkbox"
                checked={checkboxValues.timeMaterials}
                onChange={() =>
                  setCheckboxValues((c) => {
                    return { ...c, timeMaterials: !c.timeMaterials };
                  })
                }
              />
              <span className="checkmark" />
            </>
          )}
        </label>

        {renderTimeMaterials()}
      </div>

      <div className="break-wrapper">
        <div className="checkbox" />
      </div>

      <div className="section-wrapper">
        <label className="checkbox container">
          {view !== "approved" && view !== "searched" && (
            <>
              <input
                className="checkbox"
                type="checkbox"
                checked={checkboxValues.material}
                onChange={() =>
                  setCheckboxValues((c) => {
                    return { ...c, material: !c.material };
                  })
                }
              />
              <span className="checkmark" />
            </>
          )}
        </label>

        {renderMaterials()}
      </div>

      <div className="break-wrapper">
        <div className="checkbox" />
        <hr />
      </div>

      <div className="section-three">{renderPhaseTables}</div>

      <div className="break-wrapper">
        <div className="checkbox" />
        <hr />
      </div>

      <div className="totals">
        <div className="checkbox" />
        <VerificationTotals formData={parData} windowWidth={windowWidth} />
      </div>

      {(parData.statusComments?.length > 0 ||
        parFormDataState.statusComments?.length > 0) && (
        <div className="totals">
          <div className="checkbox" />
          <div className="tables">
            <ApproverChanges
              parData={view === "par" ? parFormDataState : parData}
              color={"black"}
            />
          </div>
        </div>
      )}

      <div className={`buttons-wrapper ${view === "confirm" && "confirm"}`}>
        <div className="break-wrapper">
          <div className="checkbox" />
        </div>

        <div className="buttons">
          {view !== "approved" && view !== "searched" && (
            <button
              className="save"
              disabled={
                !allBoxesChecked || hideButton || rejectMessage.length !== 0
              }
              onClick={handleParSubmit}
            >
              {hideButton
                ? "Loading"
                : view === "confirm"
                ? "Approve"
                : "Submit"}
            </button>
          )}
          {view === "par" && (
            <button
              className="Email"
              onClick={() => {
                handleOpenModal("printEmail");
              }}
            >
              T&M Email
            </button>
          )}

          {view === "confirm" && (
            <button
              className="reject"
              disabled={disableRejectButton || rejectMessage.length <= 0}
              onClick={handleRejectMessage}
            >
              {disableRejectButton ? "Loading" : "Reject"}
            </button>
          )}

          <Link
            to={{
              pathname: `/select-print${view === "par" ? "" : "/" + viewId}`,
              state: {
                prevPath: history.location.pathname,
                approvalsCheckmarks: {
                  checkboxValues: checkboxValues,
                  combinedCheckboxValues: combinedCheckboxValues,
                  phaseCheckmarkValues: phaseCheckmarkValues,
                },
              },
            }}
          >
            Print
          </Link>

          <Link
            className="cancel"
            to={{
              pathname:
                view === "par"
                  ? "/par"
                  : view === "confirm"
                  ? "/approvals"
                  : view === "searched"
                  ? "/approvals"
                  : "/overview",
              search:
                view === "searched"
                  ? `?${formattedDate}+${parData.jobNumber}`
                  : "",
              state: { bypassWarningModal: true },
            }}
          >
            Cancel
          </Link>
        </div>
      </div>

      {view === "confirm" && (
        <ParTextArea
          customClass={"reject-message-text"}
          labelName={"Comments on Rejected PAR"}
          rows={5}
          cols={50}
          placeholder={
            "Enter any changes that need to be made for the PAR to be approved."
          }
          value={rejectMessage}
          onChange={(e) => setRejectMessage(e.target.value)}
        />
      )}

      <Modal
        isModalOpen={isModalOpen.printEmail}
        onRequestClose={() => handleCloseModal("printEmail")}
      >
        <PrintEmailForm sendEmail={sendEmail} setSendEmail={setSendEmail} />
      </Modal>
    </div>
  );
};

export default VerificationPage;
