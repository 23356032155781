import ExpandCollapseSection from "../../../components/core/ExpandCollapseSection";

import { ParTextArea } from "../../../components/helpers/ParFormComponents";
import { useCurrentPar } from "../../../components/contexts/CurrentParContext";

const InjuriesSection = () => {
  const { parFormDataState } = useCurrentPar();

  const { injuries, checkboxes } = parFormDataState;

  return (
    <ExpandCollapseSection
      sectionName={"Injuries"}
      checkboxValue={checkboxes.injuries}
    >
      <ParTextArea
        customClass="single-problem-container"
        labelName="Injuries"
        rows="3"
        cols="30"
        value={injuries}
        readOnly
      />
    </ExpandCollapseSection>
  );
};

export default InjuriesSection;
