import { useState } from "react";
import { Link } from "react-router-dom";

import { useCurrentPar } from "../../components/contexts/CurrentParContext";

import { updatePerDiem } from "../../components/helpers/updatePerDiem";
import { errorToast } from "../../util/toastNotifications";

const ParButtons = ({ handleAddPhaseCode }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const { parFormDataState, parDispatch } = useCurrentPar();

  const {
    pfs,
    jobNumber,
    foreman,
    wind,
    precipitation,
    temperatureHigh,
    temperatureLow,
    date,
    safetyTopic,
    startTime,
    stopTime,
    forecast,
    injuries,
    incidents,
    idb,
    subcontractor,
    material,
    checkboxes,
  } = parFormDataState;

  const weatherIsFilledOut = () => {
    let isFilled = true;
    [temperatureHigh, temperatureLow, wind, precipitation, forecast].forEach(
      (attr) => {
        if (attr === "") {
          isFilled = false;
        }
      }
    );
    return isFilled;
  };

  const isValidTime = () => {
    if (checkboxes.overnight || startTime <= stopTime) {
      return true;
    } else {
      return false;
    }
  };

  const subcontractorsFilledOut = () => {
    let isFilled = true;
    if (subcontractor.length === 0) {
      isFilled = false;
    } else {
      subcontractor.forEach((s) => {
        if (
          s.name === "" ||
          s.description === "" ||
          s.dailyHours === "" ||
          s.employeeCount === ""
        ) {
          isFilled = false;
        }
      });
    }
    return isFilled;
  };

  const timeMaterialsFilledOut = () => {
    return !(
      parFormDataState.matCustomerCode === "" &&
      parFormDataState.matOsr === "" &&
      parFormDataState.matJobsite === ""
    );
  };

  const materialFilledOut = () => {
    let materialIsFilled = true;
    if (material.length === 0) {
      materialIsFilled = false;
    } else {
      if (material.length === 1 && material[0].id === 0) {
        materialIsFilled = true;
      } else {
        material.forEach((s) => {
          if (
            s.description === "" ||
            s.units === "0.00" ||
            s.cost === "0.00" ||
            s.total === "0.00" ||
            s.uom === "" ||
            s.phaseCode === "" ||
            s.phaseDesc === ""
          ) {
            materialIsFilled = false;
          }
        });
      }
    }
    return materialIsFilled;
  };

  const checkProgressEntry = () => {
    const progressEntryArray = [];

    Object.keys(parFormDataState.phaseCodes).forEach((phase) => {
      progressEntryArray.push(
        parFormDataState.phaseCodes[phase].progressEntry[0]?.dcrProgress
      );
    });

    const missingProgress =
      progressEntryArray.includes("0") || progressEntryArray.includes(undefined)
        ? true
        : false;

    return missingProgress;
  };

  const handleVerifyToast = () => {
    setIsDisabled(true);

    if (date === "") {
      errorToast("Please Select a Date");
    } else if (pfs === 0) {
      errorToast("Please Select PAR Approver");
    } else if (jobNumber === "") {
      errorToast("Please Select Job Number");
    } else if (!weatherIsFilledOut()) {
      errorToast("Please Complete Weather");
    } else if (safetyTopic === "") {
      errorToast("Please Enter Safety Topic");
    } else if (startTime === "") {
      errorToast("Please Enter Start Time");
    } else if (stopTime === "") {
      errorToast("Please Enter Stop Time");
    } else if (!isValidTime()) {
      errorToast("Overnight Hours Box Not Checked");
    } else if (idb.length === 0 && checkboxes.issues) {
      errorToast("Missing Issues Records");
    } else if (incidents === "" && checkboxes.incidents) {
      errorToast("Missing Incidents Notes");
    } else if (injuries === "" && checkboxes.injuries) {
      errorToast("Missing Injuries Notes.");
    } else if (checkboxes.subcontractor && !subcontractorsFilledOut()) {
      errorToast("Missing Subcontractor details.");
    } else if (checkboxes.timeMaterials && !timeMaterialsFilledOut()) {
      errorToast("Missing Time and Materials Details");
    } else if (checkboxes.material && !materialFilledOut()) {
      errorToast("Missing Materials Details");
    } else if (checkProgressEntry()) {
      errorToast("Missing Progress Entry");
    }

    setTimeout(() => {
      setIsDisabled(false);
    }, 5000);
  };

  const formIsAllFilledOut = () => {
    if (
      weatherIsFilledOut() &&
      date !== "" &&
      pfs !== 0 &&
      foreman !== "" &&
      jobNumber !== "" &&
      safetyTopic !== "" &&
      startTime !== "" &&
      stopTime !== "" &&
      isValidTime() &&
      (idb?.length > 0 || !checkboxes.issues) &&
      (incidents.length > 0 || !checkboxes.incidents) &&
      (injuries.length > 0 || !checkboxes.injuries) &&
      (subcontractorsFilledOut() || !checkboxes.subcontractor) &&
      (timeMaterialsFilledOut() || !checkboxes.timeMaterials) &&
      (materialFilledOut() || !checkboxes.material)
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="save-button-wrapper">
      <button
        className="add-phase"
        onClick={() => handleAddPhaseCode(true)}
        disabled={!jobNumber.length > 0}
      >
        ADD PHASE CODE
      </button>

      {formIsAllFilledOut() ? (
        <Link
          onClick={() => updatePerDiem(parDispatch, parFormDataState)}
          to="/par-verification/par"
          className="add-phase submit-button"
        >
          VERIFY
        </Link>
      ) : (
        <button
          onClick={() => {
            handleVerifyToast();
          }}
          disabled={isDisabled}
          className="add-phase submit-button"
        >
          VERIFY
        </button>
      )}
    </div>
  );
};

export default ParButtons;
