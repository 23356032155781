import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "../styles/toast-notifications.scss";

toast.configure();

export const errorToast = (error) => {
  toast.error(error);
};

export const infoToast = (info) => {
  toast.info(info);
};

export const successfulToast = (message) => {
  toast.success(
    <div>
      <FontAwesomeIcon icon="fa fa-check-circle" />
      {message}
    </div>,
    { hideProgressBar: true, autoClose: 2987 }
  );
};
