import { useState, useCallback, useEffect } from "react";

import ConfirmationModal from "../../../components/modals/confirmation-modal/ConfirmationModal";
import ExpandCollapseSection from "../../../components/core/ExpandCollapseSection";
import AddIssueForm from "../../../components/forms/AddIssueForm";
import IssuesSectionItem from "./IssuesSectionItem";
import Modal from "../../../components/modals/Modal";

import { ParTable } from "../../../components/helpers/ParFormComponents";
import { useModalContext } from "../../../components/contexts/ModalContext";
import { useCurrentPar } from "../../../components/contexts/CurrentParContext";

const IssuesSection = () => {
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [editIssue, setEditIssue] = useState({});

  const { handleOpenModal, handleCloseModal, isModalOpen } = useModalContext();
  const { parFormDataState, parDispatch } = useCurrentPar();

  const { jobNumber, jobDescription, idb, checkboxes } = parFormDataState;

  const addIdb = () => {
    parDispatch({
      type: "addIdb",
    });
  };

  const updateIdb = useCallback(
    (value, issueNumber) => {
      parDispatch({
        type: "updateIdb",
        value: value,
        issueNumber: issueNumber,
      });
    },
    [parDispatch]
  );

  const deleteIdb = () => {
    parDispatch({
      type: "deleteIdb",
      payload: {
        idb: selectedIssue.issueNumber,
      },
    });

    handleCloseModal("removeIssue");
  };

  const renderIssues = () => {
    return idb.map((row) => {
      return (
        <IssuesSectionItem
          key={row?.issueNumber}
          {...row}
          setSelectedIssue={setSelectedIssue}
          setEditIssue={setEditIssue}
        />
      );
    });
  };

  useEffect(() => {
    if (idb?.length > 0) {
      setSelectedIssue(idb.at(-1));
    } else {
      setSelectedIssue(null);
    }
  }, [idb]);

  return (
    <ExpandCollapseSection
      sectionName={"Issues"}
      checkboxValue={checkboxes.issues}
    >
      {idb?.length > 0 ? (
        <ParTable
          tableClass="issues-table"
          tableHeaders={[
            { class: "header type", name: "Type" },
            { class: "header date", name: "Date" },
            {
              class: "header description",
              name: "Description",
            },
            { class: "header edit", name: "" },
            { class: "header delete", name: "" },
          ]}
          tableRows={renderIssues()}
        />
      ) : (
        <h3>No Issues Entered</h3>
      )}

      <button
        className="add-button"
        onClick={() => {
          setEditIssue({});
          handleOpenModal("addIssue");
        }}
      >
        Add Issue
      </button>

      <ConfirmationModal
        isModalOpen={isModalOpen.removeIssue}
        onRequestClose={() => handleCloseModal("removeIssue")}
        onConfirm={deleteIdb}
        modalQuestion={`Are you sure you want to remove: \n\n ${selectedIssue?.description} ?`}
      />

      <Modal
        isModalOpen={isModalOpen.addIssue}
        onRequestClose={() => handleCloseModal("addIssue")}
        content={{ maxHeight: "425px", height: "100vh" }}
      >
        <AddIssueForm
          deleteRow={deleteIdb}
          addRow={addIdb}
          updateRow={updateIdb}
          issueNumber={selectedIssue?.issueNumber}
          jobNumber={jobNumber}
          jobDescription={jobDescription}
          idb={parFormDataState.idb}
          editIssue={editIssue}
        />
      </Modal>
    </ExpandCollapseSection>
  );
};

export default IssuesSection;
