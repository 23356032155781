import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getTotalHours } from "../helpers/totalHours";
import preventNumScroll from "../helpers/preventNumScroll";
import { hoursWorkedIsValid } from "./hoursWorkedIsValid";

export const renderEmployees = (
  formData,
  phaseNum,
  handleEmployeeDataChange,
  handleModal,
  handleTooManyHours,
  perDiemAmount,
  handleResetPerDiem,
  updatePerdiemHours
) => {
  const alphabetizedEmployees = [
    ...formData.phaseCodes[phaseNum].laborData,
  ].sort((firstEmployee, secondEmployee) =>
    firstEmployee.name > secondEmployee.name ? 1 : -1
  );

  return alphabetizedEmployees.map((employee) => {
    return (
      <tr className="table-row" key={employee.id}>
        <td className="padding-20">{employee.name}</td>
        <td>{employee.id}</td>

        {phaseNum === "012130-00-00" ? (
          <td>
            <input
              type="text"
              value={getTotalHours(employee.id, formData, "labor")}
              readOnly
            />
          </td>
        ) : (
          <td>
            <input
              className="employee-hours"
              type="number"
              min="0"
              max="24"
              step={0.25}
              onKeyDown={(e) => e.code === "Minus" && e.preventDefault()}
              value={employee.hoursWorked}
              onWheel={preventNumScroll}
              onBlur={(e) => {
                (hoursWorkedIsValid(formData, employee.id, "laborData") ===
                  "exceeds" &&
                  handleTooManyHours(
                    "laborData",
                    "hoursWorked",
                    employee.id,
                    "id",
                    `${employee.name} Hours Has Exceeded Total Hours From PAR Job Information Section.`
                  )) ||
                  (hoursWorkedIsValid(formData, employee.id, "laborData") ===
                    "precision" &&
                    (e.target.value = "0") &&
                    handleTooManyHours(
                      "laborData",
                      "hoursWorked",
                      employee.id,
                      "id",
                      "Cannot Input More Than Two Decimal Places."
                    ));
              }}
              onChange={(e) => {
                handleEmployeeDataChange(
                  e.target.value,
                  "laborData",
                  "hoursWorked",
                  employee.id,
                  "id"
                );
              }}
            />
          </td>
        )}

        {phaseNum === "012130-00-00" && (
          <td className="checkbox-container">
            <label htmlFor={`checkmark-small${employee.id}`} />
            <input
              tabIndex="0"
              id={`checkmark-small${employee.id}`}
              className="checkmark-small"
              type="checkbox"
              checked={employee.perDiem}
              onChange={(e) => {
                handleEmployeeDataChange(
                  e.target.checked,
                  "laborData",
                  "perDiem",
                  employee.id,
                  "id"
                );
              }}
            />
          </td>
        )}

        {phaseNum === "012130-00-00" && (
          <td>
            <input
              type="number"
              value={employee.perDiemAmount}
              onBlur={(e) =>
                e.target.value > perDiemAmount &&
                handleResetPerDiem(
                  perDiemAmount,
                  "laborData",
                  "perDiemAmount",
                  employee.id,
                  "id"
                )
              }
              onChange={(e) =>
                handleEmployeeDataChange(
                  e.target.value,
                  "laborData",
                  "perDiemAmount",
                  employee.id,
                  "id"
                )
              }
            />
          </td>
        )}

        <td>
          <input
            type="text"
            value={employee.laborNotes}
            maxLength={150}
            onChange={(e) =>
              handleEmployeeDataChange(
                e.target.value,
                "laborData",
                "laborNotes",
                employee.id,
                "id"
              )
            }
          />
        </td>

        <td className="remove-table-row">
          <FontAwesomeIcon
            icon="svg-inline--fa fa-trash"
            onClick={() =>
              handleModal("laborData", employee.name, "name", "deleteEmployee")
            }
          />
        </td>
      </tr>
    );
  });
};

export const renderEquipmentData = (
  formData,
  phaseNum,
  handleEquipmentChange,
  handleModal,
  handleTooManyHours
) => {
  return formData.phaseCodes[phaseNum].equipmentData.map((item) => {
    return (
      <tr key={item.equipNum} className="table-row">
        <td className="padding-20">
          {item.equipNum} {item.description}
        </td>

        <td>
          <input
            className="operated-hours"
            type="number"
            onKeyDown={(e) => e.code === "Minus" && e.preventDefault()}
            min="0"
            max="24"
            step={0.25}
            value={item.operatedHours}
            onWheel={preventNumScroll}
            onBlur={(e) =>
              (hoursWorkedIsValid(formData, item.equipNum, "equipmentData") ===
                "exceeds" &&
                handleTooManyHours(
                  "equipmentData",
                  "operatedHours",
                  item.equipNum,
                  "equipNum",
                  `${item.equipNum} Has Exceeded Total Hours From PAR Job Information Section`
                )) ||
              (hoursWorkedIsValid(formData, item.equipNum, "equipmentData") ===
                "precision" &&
                handleTooManyHours(
                  "equipmentData",
                  "operatedHours",
                  item.equipNum,
                  "equipNum",
                  "Cannot Input More Than Two Decimal Places."
                ))
            }
            onChange={(e) =>
              handleEquipmentChange(
                e.target.value,
                "equipmentData",
                "operatedHours",
                item.equipNum,
                "equipNum"
              )
            }
          />
        </td>

        <td>
          <input
            type="text"
            value={item.equipNote}
            maxLength={150}
            onChange={(e) =>
              handleEquipmentChange(
                e.target.value,
                "equipmentData",
                "equipNote",
                item.equipNum,
                "equipNum"
              )
            }
          />
        </td>

        <td className="remove-table-row">
          <FontAwesomeIcon
            icon="svg-inline--fa fa-trash"
            onClick={() =>
              handleModal(
                "equipmentData",
                item.equipNum,
                "equipNum",
                "deleteEquipment"
              )
            }
          />
        </td>
      </tr>
    );
  });
};

export const renderProgressEntries = (
  formData,
  phaseNum,
  handleProgressEntryChange,
  handleModal
) => {
  return formData.phaseCodes[phaseNum].progressEntry.map((item) => {
    return (
      <tr align={"left"} key={item.id} className="table-row">
        <td className="padding-20">{item.id}</td>
        <td>{item.phase}</td>
        <td>{item.description}</td>
        <td>{item.currentEstimatedUnits}</td>
        <td>{item.uom}</td>
        <td>{item.jtdProgress}</td>

        <td>
          <input
            type="number"
            onKeyDown={(e) => e.code === "Minus" && e.preventDefault()}
            min="0"
            value={item.dcrProgress}
            onChange={(e) =>
              handleProgressEntryChange(
                e.target.value,
                "progressEntry",
                "dcrProgress",
                item.id,
                "id"
              )
            }
          />
        </td>

        <td>
          <FontAwesomeIcon
            icon="svg-inline--fa fa-trash"
            onClick={() =>
              handleModal("progressEntry", item.id, "id", "deleteProgressEntry")
            }
          />
        </td>
      </tr>
    );
  });
};
