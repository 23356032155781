import { useEffect, useState } from "react";

import GeneralModalLayout from "../modals/GeneralModalLayout";

import useAbortEffect from "../hooks/useAbortEffect";
import useDebounce from "../hooks/useDebounce";
import { useModalContext } from "../contexts/ModalContext";
import { useCurrentPar } from "../contexts/CurrentParContext";

import asyncAPICall from "../../util/apiWrapper";
import { infoToast } from "../../util/toastNotifications";

const AddJobForm = ({ resetPar }) => {
  const [jobNumbers, setJobNumbers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filter, setFilter] = useState([]);
  const [selectedJob, setSelectedJob] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const jobSearchDebounce = useDebounce(searchValue);
  const { parFormDataState, parDispatch } = useCurrentPar();
  const { handleCloseModal } = useModalContext();

  const submit = () => {
    if (selectedJob.Project === parFormDataState.jobNumber) {
      infoToast("You have already selected this job number.");
      handleCloseModal("addJob");
    } else if (!selectedJob.Project) {
      handleCloseModal("addJob");
    } else {
      setIsLoading(true);
      resetPar("jobNumber");

      parDispatch({
        type: "updateInputField",
        payload: {
          field: "jobNumber",
          value: `${selectedJob.Project}`,
        },
      });

      parDispatch({
        type: "updateInputField",
        payload: {
          field: "jobDescription",
          value: `${selectedJob.Project_Name}`,
        },
      });

      parDispatch({
        type: "updateInputField",
        payload: {
          field: "jobCertified",
          value: `${selectedJob.Certified}`,
        },
      });
    }
  };

  useAbortEffect((signal) => {
    function getJobs() {
      asyncAPICall(
        "api:KE7MCNYf/vprojectswithid",
        "GET",
        null,
        null,
        (data) => {
          if (data) {
            setJobNumbers(data);
            setIsLoading(false);
          }
        },
        (err) => {
          if (!signal.aborted) {
            console.log("Getting Jobs Error", err);
          }
        },
        signal
      );
    }
    getJobs();
  }, []);

  useEffect(() => {
    setFilter(
      jobNumbers.filter(
        (jobNumber) =>
          jobNumber.Project.includes(jobSearchDebounce) ||
          jobNumber.Project_Name.toLowerCase().includes(
            jobSearchDebounce.toLowerCase()
          )
      )
    );
  }, [jobSearchDebounce, jobNumbers]);

  const renderData = () => {
    let listOfProjects = [];

    return filter.map((job) => {
      if (listOfProjects.includes(job.Project)) {
        return null;
      }

      listOfProjects.push(job.Project);

      return (
        <div
          key={job.Project}
          className={`selectable-item-wrapper ${
            selectedJob.Project === job.Project ? "selected" : ""
          }`}
          data-title={job.Project_Name}
          onClick={() => {
            if (job.Project === selectedJob.Project) {
              setSelectedJob({});
            } else {
              setSelectedJob(job);
            }
          }}
        >
          <p>{`${job.Project} ${job.Project_Name}`}</p>
        </div>
      );
    });
  };

  return (
    <GeneralModalLayout
      title={"Select A Job"}
      isLoading={isLoading}
      renderModalInfo={renderData}
      handleSubmit={submit}
      setSearchValue={setSearchValue}
    />
  );
};

export default AddJobForm;
