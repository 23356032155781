import { useState } from "react";
import { useHistory } from "react-router-dom";

import asyncAPICall from "../../../util/apiWrapper";
import useAbortEffect from "../../../components/hooks/useAbortEffect";

import { errorToast, successfulToast } from "../../../util/toastNotifications";
import { useCurrentPar } from "../../../components/contexts/CurrentParContext";
import { checkSameDayPar } from "../../../components/helpers/checkSameDayPar";
import { baseParFormData } from "../../../components/reducer/store/baseParFormData";

export default function useApprovalFunctions() {
  const { parFormDataState, parDispatch } = useCurrentPar();
  const history = useHistory();
  const pathRef = window.location.href;
  const pathRefArray = pathRef.split("/", 3);
  const dynamicPath = pathRefArray.join("/");
  const approvalPath = `/par-verification/confirm/`;
  const printPath = `/select-print/`;
  const approvalLink = `${dynamicPath}${approvalPath}`;
  const printLink = `${dynamicPath}${printPath}`;

  const [pmEmail, setPmEmail] = useState("");

  useAbortEffect(
    (signal) => {
      if (parFormDataState.jobNumber) {
        asyncAPICall(
          `api:KE7MCNYf/vprojectswithid_by_Project?project=${parFormDataState.jobNumber}`,
          "GET",
          null,
          null,
          (data) => {
            setPmEmail(data[0].PM_Email);
          },
          (err) => {
            //foreman 1111 does not currently exist
            if (!signal.aborted && parFormDataState.foreman !== 1111) {
              console.log("Getting PM Email Error", err);
            }
          },
          signal
        );
      }
    },
    [parFormDataState.jobNumber]
  );

  const submitFullPar = async (
    parData,
    approverEmail,
    setButton,
    pfs,
    foreman,
    sendEmail
  ) => {
    setButton(true);
    let sameDayValue = false;

    if (parData.dcrNumber === "") {
      sameDayValue = await checkSameDayPar(
        parData.jobNumber,
        parData.date,
        parData.foreman
      );
    }

    if (sameDayValue) {
      const errorToastPromise = new Promise((resolve, reject) => {
        resolve(
          errorToast(
            `A PAR has already been created for  ${parData.jobNumber} on ${parData.date}. SAMEDAY`
          )
        );
      });

      errorToastPromise.finally(() => {
        history.push("/par");
      });
    } else {
      const getUrl = new Promise((resolve, reject) => {
        let submission;
        if ("id" in parData) {
          asyncAPICall(
            `api:W2oHkD04/partblmain/checkForId?id=${parData.id}`,
            "GET",
            null,
            null,
            (data) => {
              if (data.length > 0) {
                const body = {
                  ...parData,
                  dcrStatus: "Pending",
                };

                body.statusComments?.forEach((sC, idx) => {
                  body.statusComments[idx].status = "Pending";
                });
                submission = {
                  url: `api:W2oHkD04/partblmain/resubmissionById?id=${parData.id}`,
                  submitPar: body,
                };
              } else {
                submission = {
                  url: "api:W2oHkD04/partblmain",
                  submitPar: parData,
                };
              }

              resolve(submission);
            },
            (err) => {
              console.log("error on fetching existing PAR", err);
              reject(err);
            }
          );
        } else {
          submission = { url: "api:W2oHkD04/partblmain", submitPar: parData };
          resolve(submission);
        }
      });
      getUrl
        .then((submission) => {
          asyncAPICall(
            submission.url,
            "POST",
            submission.submitPar,
            (res) => {
              if (res.ok) {
                successfulToast("Par Submitted Successfully");
              }
              return res.json();
            },
            (data) => {
              sendApprovalEmail(
                approverEmail,
                submission.submitPar,
                foreman,
                data.dcrNumber
              );

              sendIssueEmail(pmEmail, submission.submitPar, foreman);

              if (sendEmail.length) {
                sendTimeMaterialsEmail(
                  sendEmail,
                  parData,
                  pfs,
                  foreman,
                  data.dcrNumber
                );
              }

              localStorage.clear();

              parDispatch({
                type: "setParForm",
                form: baseParFormData,
              });

              history.push("/overview");
            },
            (err) => {
              console.error("sending PAR error: ", err);
              errorToast("Failed To Submit PAR");
            },
            null,
            true
          );
        })
        .catch(console.error);
    }
  };

  const parApprovalStatus = (status, parData, setButton) => {
    setButton(true);
    const body = {
      ...parData,
      dcrStatus: status,
    };

    body.statusComments?.forEach((sC, idx) => {
      if (status === "Reject") {
        if (idx === body.statusComments.length - 1) {
          body.statusComments[idx].status = status;
        }
      } else {
        body.statusComments[idx].status = status;
      }
    });

    asyncAPICall(
      `api:W2oHkD04/partblmain/resubmissionById?id=${parData.id}`,
      "POST",
      body,
      (res) => {
        if (res.ok) {
          history.push("/approvals");
        } else {
          errorToast(
            `Unable to submit PAR for ${parData.jobNumber} on ${parData.date}`
          );
        }
      },
      null,
      (err) => {
        console.error("Sending Updated Status Error", err);
      },
      null,
      true
    );
  };

  const sendApprovalEmail = (approverEmail, parData, foreman, dcrNumber) => {
    //foreman 1111 does not currently exist
    if (parData.foreman !== 1111) {
      asyncAPICall(
        `api:5e9BgwVw/sendgrid/dynamicEmail`,
        "POST",
        {
          to_email: `${approverEmail}`,
          template_id: "d-6e389b8ff84145748ca6ff0b7ad58864",
          data: {
            date: `${parData.date}`,
            foreman: `${foreman}`,
            jobNumber: `${parData.jobNumber}`,
            link: `${approvalLink}${dcrNumber}`,
          },
        },
        null,
        null,
        (err) => {
          console.log("Sending approval email error: ", err);
        },
        null,
        true
      );
    }
  };

  const sendIssueEmail = (pmEmail, parData, foreman) => {
    if (parData.idb[0]?.issueNumber.length > 1) {
      asyncAPICall(
        `api:5e9BgwVw/sendgrid/dynamicEmail`,
        "POST",
        {
          to_email: `${pmEmail}`,
          template_id: "d-2db62e9a0cb94c488959a15ec9f319a4",
          data: {
            date: `${parData.date}`,
            foreman: `${foreman}`,
            jobNumber: `${parData.jobNumber}`,
          },
        },
        null,
        null,
        (err) => {
          console.log("Sending issues email error: ", err);
        },
        null,
        true
      );
    }
  };

  const sendTimeMaterialsEmail = (
    sendEmail,
    parData,
    pfs,
    foreman,
    dcrNumber
  ) => {
    asyncAPICall(
      `api:5e9BgwVw/sendgrid/dynamicEmail`,
      "POST",
      {
        to_email: `${sendEmail}`,
        template_id: "d-1a19486813524880829e09c2a962270f",
        data: {
          jobNumber: `${parData.jobNumber}`,
          date: `${parData.date}`,
          approver: `${pfs}`,
          submitter: `${foreman}`,
          link: `${printLink}${dcrNumber}`,
        },
      },
      null,
      null,
      (err) => {
        console.error("Sending Coordinator Email", err);
      },
      null,
      true
    );
  };

  return {
    submitFullPar,
    parApprovalStatus,
    sendApprovalEmail,
    sendIssueEmail,
    sendTimeMaterialsEmail,
  };
}
