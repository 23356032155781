import { useState, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";

import SiteActivityCheckboxSection from "./site-activity-checkbox-section/SiteActivityCheckboxSection";
import ConfirmationModal from "../../components/modals/confirmation-modal/ConfirmationModal";
import ParJobInformation from "./par-job-information-section/ParJobInformation";
import PhaseCodesSection from "./phase-code-section/PhaseCodesSection";
import PhaseCodeForm from "../../components/forms/PhaseCodeForm";
import AddAutoFill from "../../components/forms/AddAutofill";
import JournalEntriesSection from "./JournalEntriesSection";
import AddJobForm from "../../components/forms/AddJobForm";
import Modal from "../../components/modals/Modal";
import ParButtons from "./ParButtons";
import ParHeader from "./ParHeader";

import { useCurrentPar } from "../../components/contexts/CurrentParContext";
import { useModalContext } from "../../components/contexts/ModalContext";
import { useAuthInfo } from "../../components/contexts/AuthContext";

import asyncAPICall from "../../util/apiWrapper";
import { baseParFormData } from "../../components/reducer/store/baseParFormData";
import { convertToInputDate } from "../../util/dayString";

const ParPage = () => {
  const [bottomPhaseAdd, setBottomPhaseAdd] = useState(false);
  const [overnightAnsweredModal, setOvernightAnsweredModal] = useState(false);
  const [autofillData, setAutofillData] = useState([]);
  const [jobLocation, setJobLocation] = useState(null);
  const [allPhaseEmployees, setAllPhaseEmployees] = useState([]);
  const [allPhaseEquipment, setAllPhaseEquipment] = useState([]);
  const [formattedDate, setFormattedDate] = useState("");
  const [phaseCodeOptions, setPhaseCodeOptions] = useState([]);
  const [parHours, setParHours] = useState(0);

  const location = useLocation();
  const phaseCodesRef = useRef([]);
  const lastSelected = useRef();
  const params = useParams();
  const { user } = useAuthInfo();
  const { isModalOpen, handleOpenModal, handleCloseModal, modalDescription } =
    useModalContext();
  const { parDispatch, parFormDataState, handleResetting } = useCurrentPar();
  const { jobNumber, date, dcrNumber } = parFormDataState;

  const modalChildren = {
    addJob: <AddJobForm resetPar={resetPar} />,
    addAutofill: <AddAutoFill autofillData={autofillData} />,
    addPhaseCode: (
      <PhaseCodeForm
        allPhaseEmployees={allPhaseEmployees}
        allPhaseEquipment={allPhaseEquipment}
        lastSelected={lastSelected}
        bottomPhaseAdd={bottomPhaseAdd}
        phaseCodeOptions={phaseCodeOptions}
        ref={phaseCodesRef}
      />
    ),
  };

  function resetPar(type) {
    const resetForm = { ...baseParFormData };
    resetForm.foreman = user.employeeID;
    setAutofillData([]);
    setJobLocation(null);
    setOvernightAnsweredModal(false);
    handleResetting(true);

    if (type === "modal") {
      setFormattedDate("");
      setAllPhaseEmployees([]);
      setAllPhaseEquipment([]);
    } else {
      resetForm.date = date;
    }

    const resetDispatch = new Promise((res, rej) => {
      try {
        parDispatch({
          type: "setParForm",
          form: resetForm,
        });

        res(true);
      } catch (error) {
        rej(error.message);
      }
    });

    resetDispatch
      .then(() => handleResetting(false))
      .catch((err) => console.error("Resetting PAR error: ", err));
  }

  const handleModalResetPar = (modalType) => {
    handleResetting(true);
    resetPar("modal");
    handleCloseModal(modalType);
    localStorage.clear();
  };

  const handleAddPhaseCode = (bottomPhase) => {
    setBottomPhaseAdd(bottomPhase);
    handleOpenModal("addPhaseCode");
  };

  const handleIsOverNight = (overnight) => {
    handleCloseModal("isOvernight");

    setOvernightAnsweredModal(true);

    parDispatch({
      type: "updateCheckbox",
      payload: {
        field: "overnight",
        value: overnight,
      },
    });
  };

  useEffect(() => {
    if (dcrNumber && !location.state?.bypassWarningModal && !params?.slug) {
      handleOpenModal("editExistingPar");
    }

    if (!formattedDate && date) {
      setFormattedDate(convertToInputDate(date));
    }
  }, [
    dcrNumber,
    date,
    location.state,
    handleOpenModal,
    formattedDate,
    params?.slug,
  ]);

  useEffect(() => {
    if (lastSelected.current && !isModalOpen.addPhaseCode) {
      const selectedItem = phaseCodesRef.current.find(
        (el) => el?.id === lastSelected.current
      );

      if (selectedItem?.getBoundingClientRect().top < 150) {
        selectedItem.scrollIntoView({ block: "start" });
        window.scrollBy(0, -150);
      }

      setBottomPhaseAdd(false);
    }
  }, [isModalOpen, phaseCodesRef, bottomPhaseAdd]);

  useEffect(() => {
    const getPhaseCodes = () => {
      asyncAPICall(
        `api:KE7MCNYf/vprojectphasesbyproject?jobNumber=${jobNumber}`,
        "GET",
        null,
        null,
        (data) => {
          if (data) {
            setPhaseCodeOptions(data);
          }
        },
        (err) => {
          console.log("Getting Phases Error", err);
        }
      );
    };
    getPhaseCodes();
  }, [jobNumber]);

  return (
    <div className="par-page-container">
      <ParHeader handleModalResetPar={handleModalResetPar} />

      <ParJobInformation
        setOvernightAnsweredModal={setOvernightAnsweredModal}
        overnightAnsweredModal={overnightAnsweredModal}
        setAllPhaseEmployees={setAllPhaseEmployees}
        setAllPhaseEquipment={setAllPhaseEquipment}
        handleAddPhaseCode={handleAddPhaseCode}
        setBottomPhaseAdd={setBottomPhaseAdd}
        allPhaseEmployees={allPhaseEmployees}
        allPhaseEquipment={allPhaseEquipment}
        handleIsOverNight={handleIsOverNight}
        setFormattedDate={setFormattedDate}
        setAutofillData={setAutofillData}
        setJobLocation={setJobLocation}
        bottomPhaseAdd={bottomPhaseAdd}
        formattedDate={formattedDate}
        autofillData={autofillData}
        jobLocation={jobLocation}
        resetPar={resetPar}
        parHours={parHours}
        setParHours={setParHours}
      />

      <JournalEntriesSection />

      <SiteActivityCheckboxSection setBottomPhaseAdd={setBottomPhaseAdd} />

      <PhaseCodesSection parHours={parHours} phaseCodesRef={phaseCodesRef} />

      <ParButtons handleAddPhaseCode={handleAddPhaseCode} />

      <ConfirmationModal
        isModalOpen={isModalOpen.editExistingPar}
        onRequestClose={() => handleCloseModal("editExistingPar")}
        modalQuestion={`Are you still working on this PAR?\n\nDate: ${date}\nJob: ${jobNumber}`}
        questionClassName="edit-par-message"
        onConfirm={() => handleModalResetPar("editExistingPar")}
        yesButtonText={"Create New"}
        noButtonText={"Resume Editing"}
      />

      {Object.keys(modalChildren).includes(modalDescription) && (
        <Modal
          isModalOpen={isModalOpen[modalDescription]}
          onRequestClose={() => handleCloseModal(modalDescription)}
          content={
            modalDescription === "addIssue"
              ? { maxHeight: "425px", height: "100vh" }
              : {}
          }
        >
          {modalChildren[modalDescription]}
        </Modal>
      )}
    </div>
  );
};

export default ParPage;
