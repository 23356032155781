import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useModalContext } from "../../../components/contexts/ModalContext";
import preventNumScroll from "../../../components/helpers/preventNumScroll";

const SubcontractorsSectionItem = (props) => {
  const {
    setSelectedSubcontractor,
    name,
    description,
    dailyHours,
    employeeCount,
    id,
    type,
    updateSubcontractor,
    approvedSubcontractors,
  } = props;

  const { handleOpenModal } = useModalContext();

  const renderSubcontractorOptions = () => {
    return approvedSubcontractors.map((subcontractor) => {
      return (
        <option value={subcontractor.name} key={subcontractor.id}>
          {subcontractor.name}
        </option>
      );
    });
  };

  const handleSubcontractor = (e) => {
    const chosenSubcontractor = approvedSubcontractors.filter(
      (aS) => aS.name === e.target.value
    )[0];

    updateSubcontractor(
      {
        target: {
          value:
            chosenSubcontractor !== undefined ? chosenSubcontractor.name : "",
        },
      },
      id,
      "name"
    );
    updateSubcontractor(
      {
        target: {
          value:
            chosenSubcontractor !== undefined ? chosenSubcontractor.vendor : "",
        },
      },
      id,
      "vendor"
    );
  };

  return (
    <tr className="table-row">
      <td>
        <div className="subontractor-type">
          {type === "bhi" ? "BHI " : "Supplemental"}
        </div>
      </td>

      {type === "supplemental" || approvedSubcontractors.length === 0 ? (
        <td className="padding-20">
          <input
            type="text"
            placeholder="Subcontractor Name"
            value={name}
            name={"subcontractor-input"}
            onChange={(e) => updateSubcontractor(e, id, "name")}
            maxLength={60}
          />
        </td>
      ) : (
        <td>
          <select
            className={`select-approved-subs ${
              name === "" ? "default-value" : ""
            }`}
            value={name}
            name={"approved-subcontractors-select"}
            onChange={(e) => {
              handleSubcontractor(e);
            }}
          >
            <option className="default-option" value="">
              Select Subcontractor{" "}
            </option>

            {renderSubcontractorOptions()}
          </select>
        </td>
      )}

      <td>
        <input
          className="input-sub-details"
          type="text"
          placeholder="Subcontractor Description"
          value={description}
          name={"subcontractor-description-input"}
          onChange={(e) => updateSubcontractor(e, id, "description")}
          maxLength={80}
        />
      </td>

      <td>
        <input
          className="input-number"
          type="number"
          placeholder="Shift Duration (In Hours)"
          value={dailyHours}
          name={"shift-duration-input"}
          min="0"
          onKeyDown={(e) => e.code === "Minus" && e.preventDefault()}
          onChange={(e) => updateSubcontractor(e, id, "dailyHours")}
          onWheel={preventNumScroll}
        />
      </td>

      <td>
        <input
          className="input-number"
          type="number"
          placeholder="Employee Count"
          value={employeeCount}
          name={"employee-count-input"}
          min="0"
          onKeyDown={(e) => e.code === "Minus" && e.preventDefault()}
          onChange={(e) => updateSubcontractor(e, id, "employeeCount")}
          onWheel={preventNumScroll}
        />
      </td>

      <td className="remove-subcontractor">
        <FontAwesomeIcon
          icon="svg-inline--fa fa-trash"
          onClick={() => {
            setSelectedSubcontractor({ name, id });
            handleOpenModal("removeSubcontractor");
          }}
        />
      </td>
    </tr>
  );
};

export default SubcontractorsSectionItem;
