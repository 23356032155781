import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import ConfirmationModal from "../components/modals/confirmation-modal/ConfirmationModal";
import ParNotificationItem from "../components/ParNotificationItem";
import ExternalLink from "../components/core/ExternalLink";

import useAbortEffect from "../components/hooks/useAbortEffect";
import useDebounce from "../components/hooks/useDebounce";

import { useUserPendingApprovalPars } from "../components/contexts/UserApprovalParsContext";
import { useModalContext } from "../components/contexts/ModalContext";

import asyncAPICall from "../util/apiWrapper";
import { errorToast, successfulToast } from "../util/toastNotifications";
import { todayFormattedForInput } from "../util/dayString";
import { ErrorMessage } from "../util/ErrorMessage";

const ApprovalsPage = (props) => {
  const [isErrorMessage, setIsErrorMessage] = useState(false);
  const [selectedPAR, setSelectedPAR] = useState(false);
  const [searchedPAR, setSearchedPAR] = useState([]);
  const [searchedJob, setSearchedJob] = useState("");
  const [searchedDate, setSearchedDate] = useState("");

  const debounceJob = useDebounce(searchedJob);
  const { isModalOpen, handleOpenModal, handleCloseModal } = useModalContext();
  const {
    pendingApprovalPars,
    getPendingApprovalPars,
    handleChangePendingApprovalPars,
  } = useUserPendingApprovalPars();

  const handleSelectPendingPar = (par) => {
    setSelectedPAR(par);
    handleOpenModal("deletePar");
  };

  const handleDeletePendingPAR = () => {
    asyncAPICall(
      `api:W2oHkD04/partblmain/deactivatePAR?dcrNumber=${selectedPAR.dcrNumber}`,
      "POST",
      null,
      (res) => {
        if (res.ok) {
          successfulToast(
            `Successfully deleted ${selectedPAR.jobNumber} on ${selectedPAR.date}`
          );
        }
      },
      null,
      (err) => {
        errorToast(
          `Something went wrong with deleting ${selectedPAR.jobNumber} on ${selectedPAR.date}`
        );
      }
    );

    handleChangePendingApprovalPars(
      pendingApprovalPars.filter((item) => item.id !== selectedPAR.id)
    );
    handleCloseModal("deletePar");
  };

  useAbortEffect(
    (signal) => {
      if (searchedDate && debounceJob?.length > 0) {
        asyncAPICall(
          `api:W2oHkD04/partblmainByJobAndDate?job=${debounceJob}&date=${searchedDate}`,
          "GET",
          null,
          null,
          (data) => {
            if (!signal.aborted) setSearchedPAR(data);
          },
          (err) => {
            if (!signal.aborted) console.log("error searching", err);
          },
          signal
        );
      }
    },
    [debounceJob, searchedDate]
  );

  useEffect(() => {
    getPendingApprovalPars();
  }, [getPendingApprovalPars]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const pathName = props.history.location.search;
    const pathNameArray = pathName.slice(1, pathName.length).split("+");
    if (pathNameArray?.length > 1) {
      setSearchedDate(pathNameArray[0]);
      setSearchedJob(pathNameArray[1]);
    }
  }, [props.history.location.search]);

  return (
    <div className="approvals-page-container">
      <div className="header-wrapper">
        <h1 className="approve-submissions-header">APPROVE SUBMISSIONS</h1>

        <div className="external-links-wrapper">
          <ExternalLink
            text={`Approver\nInstructions`}
            link="https://scribehow.com/shared/PAR_Approver__CH8IlpZCQvKX5RVHV2_5yA"
          />

          <ExternalLink
            text={`PAR\nUpdates`}
            link="https://sites.google.com/bhico.com/parupdates/home"
          />
        </div>
      </div>

      <div className="approvals-wrapper">
        <div className="assigned-approvals-container">
          <h1 className="assigned-approvals-header">ASSIGNED APPROVALS</h1>

          <div className="par-forms-container">
            {pendingApprovalPars
              .map((par) => {
                return (
                  <ParNotificationItem
                    key={par.id}
                    formData={par}
                    handleSelectPendingPar={handleSelectPendingPar}
                  />
                );
              })
              .sort(
                (a, b) =>
                  a.props.formData.created_at - b.props.formData.created_at
              )}
          </div>
        </div>

        <div className="approvals-filter-container">
          <div className="search-inputs-wrapper">
            <div className="date-container inputs">
              <label htmlFor="dcrNumber">Date</label>
              <input
                id="dcrNumber"
                className="date-input"
                type="date"
                max={todayFormattedForInput()}
                value={searchedDate}
                onChange={(e) => {
                  setSearchedDate(e.target.value);
                }}
                maxLength={45}
              />
            </div>
            <div className="job-number-container inputs">
              <label htmlFor="dcrNumber">Job Number</label>
              <input
                id="dcrNumber"
                type="search"
                placeholder="Search Job"
                value={searchedJob}
                onChange={(e) => {
                  setSearchedJob(e.target.value);
                }}
                maxLength={12}
              />
            </div>
          </div>
          <div className="search-results-container">
            <h2>Search Results</h2>
            <div className="filter-search-wrapper">
              {searchedPAR.length === 0 ? (
                <h2>No PARs</h2>
              ) : (
                searchedPAR.map((par) => {
                  return (
                    <div
                      key={par.dcrNumber}
                      className="searched-report-wrapper"
                    >
                      <h3 className="searched-job-number">
                        <FontAwesomeIcon icon="fas fa-check-circle" />
                        {par.jobNumber}
                      </h3>
                      <h3 className="searched-date">
                        {par.submitterName.length > 0 &&
                        par.submitterName[0]?.name
                          ? par.submitterName[0].name
                          : "Former Employee"}
                      </h3>

                      <Link
                        className="searched-report-link"
                        to={{
                          pathname: `/par-verification/searched/${par.dcrNumber}`,
                        }}
                      >
                        View Report
                      </Link>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>

      {isErrorMessage && (
        <ErrorMessage
          errorMessage="Sorry, we could not retrieve the PAR's ready for approval."
          setIsErrorMessage={setIsErrorMessage}
        />
      )}

      <ConfirmationModal
        isModalOpen={isModalOpen.deletePar}
        onRequestClose={() => handleCloseModal("deletePar")}
        modalQuestion={`Are you sure you want to delete ${selectedPAR.jobNumber} on ${selectedPAR.date}`}
        onConfirm={handleDeletePendingPAR}
      />
    </div>
  );
};

export default ApprovalsPage;
