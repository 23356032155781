import React from "react";
import { Route, Switch } from "react-router-dom";

import VerificationPage from "./pages/verification-approvals/VerificationPage";
import OverviewPage from "./pages/overview-page/OverviewPage";
import ApprovalsPage from "./pages/ApprovalsPage";
import SelectPrintPage from "./pages/SelectPrintPage";
import Navbar from "./components/core/Navbar";
import ParPage from "./pages/par-page/ParPage";
import NoMatchPage from "./pages/NoMatchPage";

import { UserApprovalParsContextProvider } from "./components/contexts/UserApprovalParsContext";
import { ModalContextProvider } from "./components/contexts/ModalContext";
import { CurrentParContextProvider } from "./components/contexts/CurrentParContext";

const PrivateRoutes = () => {
  return (
    <div className="container">
      <div className="body-wrapper">
        <Route path="/" component={Navbar} />

        <CurrentParContextProvider>
          <ModalContextProvider>
            <UserApprovalParsContextProvider>
              <Switch>
                <Route exact path="/par" component={ParPage} />
                <Route path="/par/:slug" component={ParPage} />

                <Route path="/overview" component={OverviewPage} />

                <Route exact path="/approvals" component={ApprovalsPage} />
                <Route path="/approvals/:slug" component={ApprovalsPage} />

                <Route
                  path="/par-verification/:slug"
                  component={VerificationPage}
                />

                <Route exact path="/select-print" component={SelectPrintPage} />
                <Route path="/select-print/:slug" component={SelectPrintPage} />

                <Route component={NoMatchPage} />
              </Switch>
            </UserApprovalParsContextProvider>
          </ModalContextProvider>
        </CurrentParContextProvider>
      </div>
    </div>
  );
};

export default PrivateRoutes;
