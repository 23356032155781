import { useState } from "react";

import ExternalLink from "../../components/core/ExternalLink";
import SafetyContact from "./SafetyContact";
import ApprovedPars from "./ApprovedPars";
import PendingPars from "./PendingPars";
import { useAuthInfo } from "../../components/contexts/AuthContext";

import useAbortEffect from "../../components/hooks/useAbortEffect";
import useAbortSignal from "../../components/hooks/useAbortSignal";

import asyncAPICall from "../../util/apiWrapper";

const OverviewPage = () => {
  const [userSubmittedPars, setUserSubmittedPars] = useState([]);
  const [userApprovedPars, setUserApprovedPars] = useState([]);

  const { signal } = useAbortSignal();
  const { user } = useAuthInfo();

  const getUserSubmittedPars = () => {
    if (user?.employeeID) {
      asyncAPICall(
        `api:W2oHkD04/partblmainBySubmitter?searchedSubmitter=${user.employeeID}`,
        "GET",
        null,
        null,
        (data) => {
          if (!signal.aborted) sortPars(data);
        },
        (err) => {
          if (!signal.aborted)
            console.error("error getting submitted PAR reports", err);
        },
        signal
      );
    }
  };

  const sortPars = (arrPars) => {
    const submittedPars = [];
    const approvedPars = [];
    for (const par of arrPars) {
      if (par.dcrStatus === "Approved") {
        approvedPars.push(par);
      } else {
        submittedPars.push(par);
      }
    }
    setUserApprovedPars(approvedPars);
    setUserSubmittedPars(submittedPars);
  };

  useAbortEffect(() => {
    getUserSubmittedPars();
  }, [user.employeeID]);

  return (
    <div className="overview-page-container">
      <div className="header-wrapper">
        <h1 className="header-h1">OVERVIEW</h1>

        <div className="external-links-wrapper">
          <ExternalLink
            text={`PAR\nInstructions`}
            link="https://scribehow.com/shared/PAR_Submitter__gurwseo3Rxu1Hu-diK0G9Q"
          />

          <ExternalLink
            text={`Production\nDashboard`}
            link="https://bhico.domo.com/page/656594894"
          />

          <ExternalLink
            text={`PAR\nUpdates`}
            link="https://sites.google.com/bhico.com/parupdates/home"
          />
        </div>
      </div>

      <div className="individual-notifications-containers">
        <PendingPars
          getUserSubmittedPars={getUserSubmittedPars}
          userSubmittedPars={userSubmittedPars}
        />

        <ApprovedPars userApprovedPars={userApprovedPars} />

        <SafetyContact />
      </div>
    </div>
  );
};

export default OverviewPage;
