// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-wrapper {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 300px;
}

.notification-item {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  animation: SlideLeft 0.2s;
  animation-fill-mode: forwards;
  width: 300px;
}

@keyframes SlideLeft {
  0% {
    margin-left: 120%;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes SlideRight {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: 120%;
  }
}
.notification-item.exit {
  animation: SlideRight 0.2s;
  animation-fill-mode: forwards;
}

.notification-item p {
  margin: 0;
  padding: 10px;
  overflow: hidden;
}

.notification-item .bar {
  height: 10px;
}

.Toastify__toast--success {
  background-color: rgb(2, 185, 42);
}
.Toastify__toast--success .fas {
  padding-right: 10px;
}

.Toastify__toast--error {
  background-color: #ce3635;
}
.Toastify__toast--error .fas {
  padding-right: 10px;
}

.Toastify__toast--warning {
  background-color: #fdd656;
}
.Toastify__toast--warning .fas {
  padding-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/styles/toast-notifications.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,uCAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;EACA,6BAAA;EACA,YAAA;AACF;;AAEA;EACE;IACE,iBAAA;EACF;EAEA;IACE,cAAA;EAAF;AACF;AAGA;EACE;IACE,cAAA;EADF;EAIA;IACE,iBAAA;EAFF;AACF;AAKA;EACE,0BAAA;EACA,6BAAA;AAHF;;AAMA;EACE,SAAA;EACA,aAAA;EACA,gBAAA;AAHF;;AAMA;EACE,YAAA;AAHF;;AAMA;EACE,iCAAA;AAHF;AAIE;EACE,mBAAA;AAFJ;;AAMA;EACE,yBAAA;AAHF;AAIE;EACE,mBAAA;AAFJ;;AAMA;EACE,yBAAA;AAHF;AAIE;EACE,mBAAA;AAFJ","sourcesContent":[".notification-wrapper {\n  position: fixed;\n  top: 10px;\n  right: 10px;\n  width: 300px;\n}\n\n.notification-item {\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);\n  border-radius: 10px;\n  overflow: hidden;\n  margin-bottom: 20px;\n  animation: SlideLeft 0.2s;\n  animation-fill-mode: forwards;\n  width: 300px;\n}\n\n@keyframes SlideLeft {\n  0% {\n    margin-left: 120%;\n  }\n\n  100% {\n    margin-left: 0;\n  }\n}\n\n@keyframes SlideRight {\n  0% {\n    margin-left: 0;\n  }\n\n  100% {\n    margin-left: 120%;\n  }\n}\n\n.notification-item.exit {\n  animation: SlideRight 0.2s;\n  animation-fill-mode: forwards;\n}\n\n.notification-item p {\n  margin: 0;\n  padding: 10px;\n  overflow: hidden;\n}\n\n.notification-item .bar {\n  height: 10px;\n}\n\n.Toastify__toast--success {\n  background-color: rgb(2, 185, 42);\n  .fas {\n    padding-right: 10px;\n  }\n}\n\n.Toastify__toast--error {\n  background-color: #ce3635;\n  .fas {\n    padding-right: 10px;\n  }\n}\n\n.Toastify__toast--warning {\n  background-color: #fdd656;\n  .fas {\n    padding-right: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
