import { useState, useEffect, useCallback, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ConfirmationModal from "../../../components/modals/confirmation-modal/ConfirmationModal";
import EquipmentForm from "../../../components/forms/EquipmentForm";
import AddEmployee from "../../../components/forms/AddEmployee";
import AddAutofill from "../../../components/forms/AddAutofill";
import AddJobForm from "../../../components/forms/AddJobForm";
import Modal from "../../../components/modals/Modal";

import useAbortEffect from "../../../components/hooks/useAbortEffect";
import { useModalContext } from "../../../components/contexts/ModalContext";
import { useCurrentPar } from "../../../components/contexts/CurrentParContext";
import { useAuthInfo } from "../../../components/contexts/AuthContext";

import asyncAPICall from "../../../util/apiWrapper";
import {
  ParInput,
  ParSelect,
  ParTextArea,
} from "../../../components/helpers/ParFormComponents";
import { checkSameDayPar } from "../../../components/helpers/checkSameDayPar";
import { todayFormattedForInput } from "../../../util/dayString";
import { errorToast } from "../../../util/toastNotifications";

const ParJobInformation = (props) => {
  const {
    overnightAnsweredModal,
    setOvernightAnsweredModal,
    autofillData,
    setAutofillData,
    jobLocation,
    setJobLocation,
    formattedDate,
    setFormattedDate,
    allPhaseEmployees,
    setAllPhaseEmployees,
    allPhaseEquipment,
    setAllPhaseEquipment,
    resetPar,
    handleAddPhaseCode,
    handleIsOverNight,
    parHours,
    setParHours,
  } = props;

  const [headerToggle, setHeaderToggle] = useState(false);
  const [pfsList, setPfsList] = useState([]);
  const [jobInfoComplete, setJobInfoComplete] = useState(false);
  const [isWeatherLoading, setIsWeatherLoading] = useState(false);
  const [startHours, setStartHours] = useState(0);
  const [endHours, setEndHours] = useState(0);
  const [timeChange, setTimeChange] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const stopTimeRef = useRef(null);
  const { user } = useAuthInfo();
  const { isModalOpen, handleOpenModal, handleCloseModal, modalDescription } =
    useModalContext();
  const { parFormDataState, parDispatch, resetting, handleResetting } =
    useCurrentPar();

  const {
    pfs,
    jobNumber,
    dcrNumber,
    foreman,
    wind,
    precipitation,
    temperatureHigh,
    temperatureLow,
    date,
    safetyTopic,
    startTime,
    stopTime,
    forecast,
    checkboxes,
  } = parFormDataState;

  const localStorageEmployees = JSON.parse(
    localStorage.getItem("allPhaseEmployees")
  )
    ? JSON.parse(localStorage.getItem("allPhaseEmployees"))
    : [];
  const [prevAllPhaseEmployees, setPrevAllPhaseEmployees] = useState(
    localStorageEmployees
  );
  const localStorageEquipment = JSON.parse(
    localStorage.getItem("allPhaseEquipment")
  )
    ? JSON.parse(localStorage.getItem("allPhaseEquipment"))
    : [];
  const [prevAllPhaseEquipment, setPrevAllPhaseEquipment] = useState(
    localStorageEquipment
  );

  const removeFocus = () => {
    stopTimeRef.current?.blur();
  };

  const basicFieldUpdate = useCallback(
    (value, field, type) => {
      parDispatch({
        type,
        payload: {
          field,
          value,
        },
      });
    },
    [parDispatch]
  );

  const handleOvernightCheckbox = () => {
    parDispatch({
      type: "updateCheckbox",
      payload: {
        field: "overnight",
        value: !checkboxes.overnight,
      },
    });

    if (stopTime < startTime) {
      if (checkboxes.overnight === true) {
        setParHours(`Enter Valid Stop Time`);
      } else {
        setOvernightAnsweredModal(true);
      }
    } else {
      setOvernightAnsweredModal(true);
    }
  };

  const handleHasDateCheck = () => {
    if (formattedDate) {
      handleOpenModal("addJob");
    } else {
      errorToast("Please choose a date before adding a job number.");
    }
  };

  const getWeather = useCallback(async () => {
    setIsWeatherLoading(true);
    const accuWeatherKey = process.env.REACT_APP_ACCUWEATHER_API_KEY;
    try {
      if (jobNumber !== "") {
        if (jobLocation !== null) {
          const locationKey = await fetch(
            `https://dataservice.accuweather.com/locations/v1/cities/geoposition/search?apikey=${accuWeatherKey}&q=${jobLocation}`
          )
            .then((res) => {
              if (res.ok) {
                return res.json();
              } else {
                errorToast(
                  "Location Error: Unable to Get Location Data From AccuWeather."
                );
                return null;
              }
            })
            .then((data) => {
              return data.Key;
            });

          await fetch(
            `https://dataservice.accuweather.com/forecasts/v1/daily/1day/${locationKey}?apikey=${accuWeatherKey}&details=true`
          )
            .then((res) => {
              if (res.ok) {
                return res.json();
              } else {
                errorToast("AccuWeather Service Error: No Forecast Available");
                return null;
              }
            })
            .then((data) => {
              if (Object.keys(data).length > 0) {
                const dailyForeCast = data.DailyForecasts[0];
                const payload = {
                  temperatureHigh:
                    dailyForeCast.Temperature.Maximum.Value?.toString(),
                  temperatureLow:
                    dailyForeCast.Temperature.Minimum.Value?.toString(),
                  wind: `${dailyForeCast.Day.Wind.Speed.Value} MPH Gusts up to ${dailyForeCast.Day.WindGust.Speed.Value} MPH`,
                  forecast: dailyForeCast.Day.LongPhrase,
                  precipitation: dailyForeCast.Day.PrecipitationType
                    ? dailyForeCast.Day.PrecipitationType
                    : "None",
                };
                parDispatch({
                  type: "setWeatherInputs",
                  payload: payload,
                });
              }
              setIsWeatherLoading(false);
              return data;
            });
        } else {
          setIsWeatherLoading(false);
          errorToast("Location Error: Job Location Missing From Viewpoint.");
        }
      } else {
        setIsWeatherLoading(false);
        errorToast("Please select a job number");
      }
    } catch (error) {
      console.error("Get Weather Error: ", error);
      setIsWeatherLoading(false);
    }
  }, [jobLocation, parDispatch, jobNumber]);

  useAbortEffect(
    (signal) => {
      if (jobNumber && parFormDataState.foreman) {
        checkSameDayPar(jobNumber, date, parFormDataState.foreman).then(
          (res) => {
            if (res && !dcrNumber) {
              errorToast(
                `A PAR has already been created for ${jobNumber} with your user credentials for ${formattedDate}.`
              );
              basicFieldUpdate("", "jobNumber", "updateInputField");
              handleCloseModal("addJob");
            } else {
              const latLonLocation = new Promise((res, rej) => {
                asyncAPICall(
                  `api:W2oHkD04/vlatlongByJob?jobSearch=${jobNumber}`,
                  "GET",
                  null,
                  null,
                  (data) => {
                    setJobLocation(data[0]?.latLong);
                    res(true);
                  },
                  (err) => {
                    if (signal.aborted) {
                      res(true);
                    } else {
                      rej(err);
                      console.error(
                        `No Location Information Set for Job Number ${jobNumber}.`
                      );
                      errorToast(
                        `No Location Information Set for Job Number ${jobNumber}.`
                      );
                    }
                  },
                  signal
                );
              });

              const multipleApproverPromise = new Promise((res, rej) => {
                asyncAPICall(
                  `api:W2oHkD04/vmultipleapproversbyjob?job=${jobNumber}`,
                  "GET",
                  null,
                  null,
                  (data) => {
                    if (data.length > 0) {
                      setPfsList(data);
                      res(true);
                    } else {
                      setPfsList([]);
                      errorToast("No Approvers Assinged To Job");
                      res(true);
                    }
                  },
                  (err) => {
                    if (signal.aborted) {
                      res(true);
                    } else {
                      rej(err);
                      console.error("Get PM Error", err);
                    }
                  },
                  signal
                );
              });

              const autofillPromise = new Promise((res, rej) => {
                asyncAPICall(
                  `api:W2oHkD04/dcrtblautofill?jobNumber=${jobNumber}`,
                  "GET",
                  null,
                  null,
                  (data) => {
                    if (data) {
                      setAutofillData(data);
                      res(true);
                    }
                  },
                  (err) => {
                    if (signal.aborted) {
                      res(true);
                    } else {
                      rej(err);
                      console.error("Getting Autofill Data Error", err);
                    }
                  },
                  signal
                );
              });

              Promise.all([
                latLonLocation,
                multipleApproverPromise,
                autofillPromise,
              ])
                .then(
                  () => {
                    if (!signal.aborted) {
                      handleCloseModal("addJob");
                      handleResetting(false);
                    }
                  },
                  (err) => console.log("Failed to get subcontractor", err)
                )
                .catch((err) => console.error(err));
            }
          }
        );
      }
    },
    [
      jobNumber,
      basicFieldUpdate,
      parDispatch,
      date,
      formattedDate,
      parFormDataState.foreman,
      dcrNumber,
    ]
  );

  useEffect(() => {
    parDispatch({
      type: "updateInputField",
      payload: {
        field: "foreman",
        value: user.employeeID,
      },
    });
  }, [parDispatch, user.employeeID]);

  useEffect(() => {
    const currentLocation = location.pathname === "/par" || `/par+${dcrNumber}`;

    const unblock = history.block((location, action) => {
      const destination =
        location.pathname === "/par-verification/par" ||
        `/par-verification/${dcrNumber}`;

      if (currentLocation && destination && action === "POP") {
        errorToast("Click 'Verify' to Continue");

        return false;
      }

      return true;
    });

    return () => {
      unblock();
    };
  }, [location, history, dcrNumber]);

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("allPhaseEmployees"));
    if (savedData) {
      setAllPhaseEmployees(
        JSON.parse(localStorage.getItem("allPhaseEmployees"))
      );
    }
  }, [setAllPhaseEmployees]);

  useEffect(() => {
    localStorage.setItem(
      "allPhaseEmployees",
      JSON.stringify(allPhaseEmployees)
    );
  }, [allPhaseEmployees]);

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("allPhaseEquipment"));
    if (savedData) {
      setAllPhaseEquipment(
        JSON.parse(localStorage.getItem("allPhaseEquipment"))
      );
    }
  }, [setAllPhaseEquipment]);

  useEffect(() => {
    localStorage.setItem(
      "allPhaseEquipment",
      JSON.stringify(allPhaseEquipment)
    );
  }, [allPhaseEquipment]);

  useEffect(() => {
    if (
      pfs !== 0 &&
      foreman.length !== "" &&
      jobNumber.length > 0 &&
      date.length > 0 &&
      forecast.length > 0 &&
      precipitation.length > 0 &&
      temperatureHigh.length > 0 &&
      temperatureLow.length > 0 &&
      wind.length > 0 &&
      safetyTopic.length > 0 &&
      startTime.length > 0 &&
      stopTime.length > 0
    ) {
      setJobInfoComplete(true);
    } else {
      setJobInfoComplete(false);
    }
  }, [
    pfs,
    foreman,
    jobNumber,
    date,
    forecast,
    precipitation,
    temperatureHigh,
    temperatureLow,
    wind,
    safetyTopic,
    startTime,
    stopTime,
  ]);

  useEffect(() => {
    setStartHours(startTime.slice(0, 2));
    setEndHours(stopTime.slice(0, 2));
    const startMinutes = startTime.slice(3, 5);
    const endMinutes = stopTime.slice(3, 5);
    let minutes = String(endMinutes - startMinutes);
    let hours = String(endHours - startHours);

    const intMin = Number(minutes);

    if (intMin < 0) {
      hours = String(Number(hours) - 1);
      minutes = String(Number(minutes) + 60);
    }

    if (minutes.length < 2) {
      minutes = `0${minutes}`;
    }

    if (Number(hours) <= 0 && startHours !== "" && endHours !== "") {
      if (
        startTime > stopTime &&
        checkboxes.overnight === false &&
        overnightAnsweredModal === false
      ) {
        removeFocus();

        handleOpenModal("isOvernight");

        return;
      } else if (startTime > stopTime && checkboxes.overnight === false) {
        setParHours("Enter Valid Stop Time");
        return;
      }

      hours = 24 - Number(startHours) + Number(endHours);

      if (hours > 23) {
        hours -= 24;
      }

      if (Number(endMinutes) < Number(startMinutes)) {
        hours--;
      }

      if (hours === -1) {
        hours = 0;
      }

      setParHours(`${hours}:${minutes}`);
    } else if (startHours !== "" && endHours !== "") {
      setParHours(`${hours}:${minutes}`);
    } else {
      setParHours("");
    }
  }, [
    startTime,
    stopTime,
    endHours,
    startHours,
    setParHours,
    overnightAnsweredModal,
    checkboxes.overnight,
    parFormDataState.dcrNumber,
    handleOpenModal,
  ]);

  useEffect(() => {
    if (
      !(startTime === "" || stopTime === "") &&
      startTime !== "" &&
      stopTime !== "" &&
      stopTime < startTime &&
      timeChange
    ) {
      setTimeChange(false);
    }
  }, [startTime, stopTime, timeChange]);

  const formatDateYearEnd = (date) => {
    const splitDate = date.split("-");
    const year = splitDate[0].slice(2);
    const arrangedDate = `${splitDate[1]}/${splitDate[2]}/${year}`;

    return arrangedDate;
  };

  const handleEmployeeAutofill = () => {
    setPrevAllPhaseEmployees([...allPhaseEmployees]);
    handleOpenModal("addAllPhaseEmployee");
  };

  const handleEquipmentAutofill = () => {
    setPrevAllPhaseEquipment([...allPhaseEquipment]);
    handleOpenModal("addAllPhaseEquipment");
  };

  const handleTimeBlur = () => {
    setTimeChange(true);

    if (checkboxes.overnight === true && overnightAnsweredModal === false) {
      handleOpenModal("isOvernight");

      parDispatch({
        type: "updateCheckbox",
        payload: {
          field: "overnight",
          value: !checkboxes.overnight,
        },
      });
    }
  };

  useEffect(() => {
    const newDate = formatDateYearEnd(formattedDate);
    if (formattedDate === "") {
      basicFieldUpdate("", "date", "updateInputField");
    } else {
      basicFieldUpdate(newDate, "date", "updateInputField");
    }
  }, [formattedDate, basicFieldUpdate]);

  useAbortEffect((signal) => {
    const pathName = window.location.pathname;
    const pathNameArray = pathName.split("+");
    const dcrNumber = pathNameArray[1];
    if (dcrNumber) {
      const getParReportPromise = new Promise((resolve, reject) => {
        asyncAPICall(
          `api:W2oHkD04/partblmain/parNumber?parNumber=${dcrNumber}`,
          "GET",
          null,
          null,
          (data) => {
            parDispatch({
              type: "setParForm",
              form: data,
            });
            resolve(true);
          },
          (err) => reject("Error PAR report", err),
          signal
        );
      });

      getParReportPromise.catch(console.error);
    }
  }, []);

  const modalChildren = {
    addJob: <AddJobForm resetPar={resetPar} />,
    addAutofill: <AddAutofill autofillData={autofillData} />,
    addAllPhaseEmployee: (
      <AddEmployee
        allPhaseEmployees={allPhaseEmployees}
        setAllPhaseEmployees={setAllPhaseEmployees}
        prevAllPhaseEmployees={prevAllPhaseEmployees}
        closeModal={() => handleCloseModal("addAllPhaseEmployee")}
      />
    ),
    addAllPhaseEquipment: (
      <EquipmentForm
        hoursWorked={"0"}
        allPhaseEquipment={allPhaseEquipment}
        setAllPhaseEquipment={setAllPhaseEquipment}
        prevAllPhaseEquipment={prevAllPhaseEquipment}
        closeModal={() => handleCloseModal("addAllPhaseEquipment")}
      />
    ),
  };

  const confirmationModalInfo = {
    isOvernight: {
      modalQuestion: (
        <p>
          Does this include <u>Overnight</u> work?
        </p>
      ),
      onConfirm: () => handleIsOverNight(true),
    },
  };

  return (
    <div className="par-information-container">
      <button
        className={`job-information ${
          jobInfoComplete ? "" : "header-alternate"
        }`}
        onClick={() => setHeaderToggle((prevToggle) => !prevToggle)}
      >
        JOB INFORMATION
      </button>
      <div
        className={`main-content-container ${
          headerToggle ? "hide-header" : ""
        }`}
      >
        <div className="job-information-wrapper">
          <ParInput
            type="date"
            label="Date"
            customClass="date-container"
            value={formattedDate}
            onChange={(e) => {
              setFormattedDate(e.target.value);
            }}
            disabled={resetting}
            max={todayFormattedForInput()}
          />

          <div className="par-button-wrapper">
            <button
              className="add-job"
              onClick={handleHasDateCheck}
              disabled={resetting}
            >
              Add Job Number
            </button>
          </div>

          <div className="job-number-wrapper">
            <label htmlFor="job-number">Job Number</label>
            <input
              type="text"
              value={jobNumber}
              name="job"
              id="job-number"
              readOnly
            />
          </div>

          <ParSelect
            label="Approver"
            customClass="pfs-container"
            idKey="Approver"
            options={pfsList}
            value={pfs}
            displayKeys={["FullName"]}
            onChange={(e) => {
              parDispatch({
                type: "updatePfs",
                payload: {
                  pfs: Number(e.target.value),
                },
              });
            }}
            disabled={resetting}
          />

          <div className="par-button-wrapper">
            <button
              className="autofill-phase-code"
              onClick={() => handleOpenModal("addAutofill")}
              disabled={!autofillData.length > 0}
            >
              Autofill Phase Codes
            </button>

            <button
              className="add-phase-code"
              onClick={() => handleAddPhaseCode(false)}
              disabled={!jobNumber.length > 0}
            >
              Add Phase Codes
            </button>

            <button
              className="employee-autofill"
              onClick={() => handleEmployeeAutofill()}
              disabled={!jobNumber.length > 0}
            >
              All Phase Employee Add
            </button>

            <button
              className="equipment-autofill"
              onClick={() => handleEquipmentAutofill()}
              disabled={!jobNumber.length > 0}
            >
              All Phase Equipment Add
            </button>
          </div>

          <ParInput
            type="text"
            label="Safety Topic"
            customClass="safety-topic-container"
            value={parFormDataState.safetyTopic}
            onChange={(e) =>
              basicFieldUpdate(
                e.target.value,
                "safetyTopic",
                "updateInputField"
              )
            }
            disabled={resetting}
          />
        </div>

        <div className="job-information-wrapper">
          <div className="weather-autofill-container">
            <button
              className="weather-link"
              onClick={() => getWeather()}
              disabled={!isWeatherLoading && !jobLocation}
            >
              {!isWeatherLoading ? (
                <FontAwesomeIcon icon="fa fa-cloud-sun-rain" />
              ) : (
                <FontAwesomeIcon icon="fa fa-spinner" />
              )}
              {!jobLocation ? " No Job Location" : " Weather Autofill"}
            </button>
          </div>

          <ParInput
            type="number"
            label="Temp High"
            customClass="temperatureHigh-container"
            value={temperatureHigh}
            onChange={(e) =>
              basicFieldUpdate(
                e.target.value,
                "temperatureHigh",
                "updateInputField"
              )
            }
            disabled={resetting}
          />

          <ParInput
            type="number"
            label="Temp Low"
            customClass="temperatureLow-container"
            value={temperatureLow}
            onChange={(e) =>
              basicFieldUpdate(
                e.target.value,
                "temperatureLow",
                "updateInputField"
              )
            }
            disabled={resetting}
          />

          <ParInput
            type="text"
            label="Wind"
            customClass="wind-container"
            value={wind}
            onChange={(e) =>
              basicFieldUpdate(e.target.value, "wind", "updateInputField")
            }
            disabled={resetting}
          />

          <ParInput
            type="text"
            label="Precipitation"
            customClass="precipitation-container"
            value={precipitation}
            onChange={(e) =>
              basicFieldUpdate(
                e.target.value,
                "precipitation",
                "updateInputField"
              )
            }
            disabled={resetting}
          />

          <ParTextArea
            customClass="forecast-container"
            labelName="Forecast"
            rows={4}
            cols={10}
            type="text"
            value={forecast}
            onChange={(e) =>
              basicFieldUpdate(
                e.target.value.slice(0, 76),
                "forecast",
                "updateInputField"
              )
            }
            disabled={resetting}
          />

          <ParInput
            type="time"
            label="Start Time"
            customClass="start-time-container"
            value={startTime}
            onChange={(e) =>
              basicFieldUpdate(e.target.value, "startTime", "updateInputField")
            }
            onBlur={() => handleTimeBlur()}
            disabled={resetting}
          />

          <div className="stop-time-container">
            <label htmlFor="stop-time-input">Stop Time</label>
            <input
              type="time"
              id="stop-time-input"
              value={stopTime}
              ref={stopTimeRef}
              onChange={(e) => {
                basicFieldUpdate(
                  e.target.value,
                  "stopTime",
                  "updateInputField"
                );
              }}
              onBlur={() => handleTimeBlur()}
              disabled={resetting}
            />
          </div>

          <div className="overnight-checkbox">
            <input
              id="overnight-confirmation"
              type="checkbox"
              checked={checkboxes.overnight}
              onChange={handleOvernightCheckbox}
            />
            <label htmlFor="overnight-confirmation">Overnight Hours?</label>
          </div>

          <div className="hours-worked-container">
            <label htmlFor="hours-worked">Hours Worked</label>
            <input
              type="text"
              value={parHours}
              name="hours-worked"
              id="hours-worked"
              readOnly
            />
          </div>
        </div>
      </div>

      {Object.keys(modalChildren).includes(modalDescription) && (
        <Modal
          isModalOpen={isModalOpen[modalDescription]}
          onRequestClose={() => handleCloseModal(modalDescription)}
          content={
            modalDescription === "addIssue"
              ? { maxHeight: "425px", height: "100vh" }
              : {}
          }
        >
          {modalChildren[modalDescription]}
        </Modal>
      )}

      {Object.keys(confirmationModalInfo).includes(modalDescription) && (
        <ConfirmationModal
          isModalOpen={isModalOpen[modalDescription]}
          onRequestClose={() => handleCloseModal(modalDescription)}
          onConfirm={confirmationModalInfo[modalDescription].onConfirm}
          modalQuestion={confirmationModalInfo[modalDescription].modalQuestion}
        />
      )}
    </div>
  );
};

export default ParJobInformation;
