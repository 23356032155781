import ExternalLink from "../../components/core/ExternalLink";
import ApproverChanges from "../../components/ApproverChanges";

import ConfirmationModal from "../../components/modals/confirmation-modal/ConfirmationModal";

import { useModalContext } from "../../components/contexts/ModalContext";
import { useCurrentPar } from "../../components/contexts/CurrentParContext";

export default function ParHeader({ handleModalResetPar }) {
  const { parFormDataState, lastSaved } = useCurrentPar();
  const { handleOpenModal, handleCloseModal, isModalOpen } = useModalContext();

  return (
    <div className="header-container">
      <div className="reset-information-wrapper">
        <ExternalLink
          text={`PAR\nInstructions`}
          link="https://scribehow.com/shared/PAR_Submitter__gurwseo3Rxu1Hu-diK0G9Q"
        />

        <div className="reset-par" onClick={() => handleOpenModal("resetPar")}>
          Reset PAR
        </div>
      </div>

      <div className="par-message">Status: {parFormDataState.dcrStatus}</div>
      <div className="par-message">{lastSaved}</div>
      <h1 className="par-header">PROJECT ACTIVITY RECORD</h1>
      <div>
        {parFormDataState.statusComments?.length > 0 && (
          <ApproverChanges parData={parFormDataState} color={"red"} />
        )}
      </div>

      <ConfirmationModal
        isModalOpen={isModalOpen.resetPar}
        onRequestClose={() => handleCloseModal("resetPar")}
        modalQuestion={"Are you sure you want to clear your PAR?"}
        onConfirm={() => handleModalResetPar("resetPar")}
      />
    </div>
  );
}
