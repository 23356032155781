import { useState } from "react";

import { useCurrentPar } from "../../../components/contexts/CurrentParContext";
import useAbortEffect from "../../../components/hooks/useAbortEffect";
import asyncAPICall from "../../../util/apiWrapper";
import PhaseCodeItem from "./PhaseCodeItem";

const PhaseCodesSection = (props) => {
  const { parHours, phaseCodesRef } = props;
  const [perDiem, setPerDiem] = useState(false);
  const [perDiemAmount, setPerDiemAmount] = useState(0);
  const { parFormDataState } = useCurrentPar();
  const { jobNumber, phaseCodes } = parFormDataState;

  const movePerDiem = () => {
    const listOfKeys = Object.keys(phaseCodes);
    if (listOfKeys.includes("012130-00-00")) {
      const perDiemPhaseCodeIndex = listOfKeys.indexOf("012130-00-00");

      listOfKeys.splice(perDiemPhaseCodeIndex, 1);

      listOfKeys.push("012130-00-00");

      return listOfKeys;
    } else {
      return listOfKeys;
    }
  };

  useAbortEffect(
    (signal) => {
      if (jobNumber) {
        asyncAPICall(
          `api:KE7MCNYf/vprojectswithid_by_Project?project=${jobNumber}`,
          "GET",
          null,
          null,
          (data) => {
            if (data.length > 0) {
              if (data[0].perDiem === "Y") {
                setPerDiem(true);
                setPerDiemAmount(data[0].perDiemAmount);
              }
            }
          },
          (err) => {
            if (signal.aborted) {
            } else {
              console.error("Setting Per Diem Error", err);
            }
          },
          signal
        );
      }
    },
    [jobNumber]
  );

  return (
    <div className="phasecode-table-container">
      {movePerDiem().map((item) => {
        return (
          <PhaseCodeItem
            key={item}
            ref={phaseCodesRef}
            item={phaseCodes[item]}
            phaseNum={item}
            parHours={parHours}
            perDiemAmount={perDiemAmount}
            perDiem={perDiem}
          />
        );
      })}
    </div>
  );
};

export default PhaseCodesSection;
