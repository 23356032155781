import { useEffect, useState } from "react";

import LoadingSpinner from "../helpers/LoadingSpinner";

import useAbortEffect from "../hooks/useAbortEffect";
import { useCurrentPar } from "../contexts/CurrentParContext";

import asyncAPICall from "../../util/apiWrapper";
import { useModalContext } from "../contexts/ModalContext";
import { successfulToast } from "../../util/toastNotifications";

const PrintEmailForm = (props) => {
  const { sendEmail, setSendEmail } = props;
  const { parFormDataState } = useCurrentPar();
  const { handleCloseModal } = useModalContext();

  const defaultEmailArray = [
    "industrial42@bhico.com",
    "wireless.comm@bhico.com",
    "bakken.electric@bhico.com",
    "civil.excavation@bhico.com",
    "facilities.pipeline@bhico.com",
    "commercial.electric@bhico.com",
  ];

  const [emailArray, setEmailArray] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  const renderEmails = () => {
    return emailArray.map((email) => {
      return (
        <option value={email} className="rendered-email" key={email}>
          {email}
        </option>
      );
    });
  };

  const handleSetEmail = () => {
    handleCloseModal("printEmail");
    successfulToast(`Set email: ${sendEmail} to send on PAR submission`);
  };

  const handleClearEmail = () => {
    setSendEmail("");
    handleCloseModal("printEmail");
  };

  useAbortEffect((signal) => {
    asyncAPICall(
      `api:W2oHkD04/vmultipleapproversbyjob?job=${parFormDataState.jobNumber}`,
      "GET",
      null,
      null,
      (data) => {
        if (data) {
          const emailArray = [...defaultEmailArray];

          data.forEach((singleEmail) => {
            if (!emailArray.includes(singleEmail.Email)) {
              emailArray.push(singleEmail.Email);
            }
          });
          setEmailArray(emailArray);
        }
      },
      (err) => {
        if (!signal.aborted) {
          console.error("Error Getting Approver Emails", err);
        }
      },
      signal
    );
  }, []);

  useEffect(() => {
    setIsDisabled(() => (sendEmail.length ? false : true));
  }, [sendEmail]);

  return (
    <div className="main-modal-container">
      <div className="inside-container">
        <h1>Select an Email</h1>

        <div className="select-email-wrapper">
          {emailArray.length ? (
            <select
              name="email-select"
              className="email-select"
              defaultValue={sendEmail}
              onChangeCapture={(e) => {
                setSendEmail(e.target.value);
              }}
            >
              <option value="">** Please Select an Email **</option>
              {renderEmails()}
            </select>
          ) : (
            <LoadingSpinner />
          )}
        </div>

        <div className="buttons-wrapper">
          <button disabled={isDisabled} onClick={() => handleSetEmail()}>
            Yes
          </button>

          <button onClick={() => handleClearEmail()}>No</button>
        </div>
      </div>
    </div>
  );
};

export default PrintEmailForm;
