export const baseParFormData = {
  dcrNumber: "",
  date: "",
  pfs: 0,
  foreman: "",
  jobNumber: "",
  jobDescription: "",
  phaseCodes: {},
  safetyTopic: "",
  temperatureHigh: "",
  temperatureLow: "",
  wind: "",
  precipitation: "",
  forecast: "",
  startTime: "",
  stopTime: "",
  notes: "",
  workPreformed: "",
  workForecasted: "",
  issues: "",
  idb: [],
  incidents: "One or more Incidents were reported in accordance with SOP's.",
  injuries: "One or more Injuries were reported in accordance with SOP's.",
  subcontractor: [],
  material: [],
  matCustomer: "",
  matCustomerCode: "",
  matOsr: "",
  matJobsite: "",
  dcrStatus: "Pending",
  checkboxes: {
    issues: true,
    injuries: true,
    subcontractor: true,
    incidents: true,
    material: true,
    timeMaterials: true,
    overnight: false,
  },
};
