import { useState, forwardRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ConfirmationModal from "../../../components/modals/confirmation-modal/ConfirmationModal";
import EquipmentForm from "../../../components/forms/EquipmentForm";
import AddClassForm from "../../../components/forms/AddClassForm";
import AddEmployee from "../../../components/forms/AddEmployee";
import Modal from "../../../components/modals/Modal";

import useAbortEffect from "../../../components/hooks/useAbortEffect";

import getDescriptionFromClass from "../../../components/helpers/getDescriptionFromClass";
import PrevailingWagesTable from "../../../components/helpers/PrevailingWagesTable";
import asyncAPICall from "../../../util/apiWrapper";
import {
  ParTable,
  ParTextArea,
} from "../../../components/helpers/ParFormComponents";
import { useCurrentPar } from "../../../components/contexts/CurrentParContext";
import { errorToast } from "../../../util/toastNotifications";
import {
  renderEmployees,
  renderEquipmentData,
  renderProgressEntries,
} from "../../../components/helpers/ParTableFunctions";
import {
  calculateSectionValues,
  calculateTotalPerDiemHours,
} from "../../../components/helpers/calculateHours";

const PhaseCodeItem = forwardRef((props, ref) => {
  const { item, phaseNum, perDiemAmount, perDiem } = props;

  const [isDeletePrevailingWageModalOpen, setIsDeletePrevailingWageModalOpen] =
    useState(false);
  const [isPrevailingWageModalOpen, setIsPrevailingWageModalOpen] =
    useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [collapseProgressEntry, setCollapseProgressEntry] = useState(false);
  const [progressEntry, setProgressEntry] = useState(item.progressEntry);
  const [collapseEquipment, setCollapseEquipment] = useState(false);
  const [collapsePhaseNote, setCollapsePhaseNote] = useState(false);
  const [collapseEmployee, setCollapseEmployee] = useState(false);
  const [isPhaseModalOpen, setIsPhaseModalOpen] = useState(false);
  const [isProgressEmpty, setIsProgressEmpty] = useState(false);
  const [modalDescription, setModalDescription] = useState("");
  const [classesSelected, setClassesSelected] = useState(null);
  const [employeeClasses, setEmployeeClasses] = useState([]);
  const [pwClassesByJob, setPwClassesByJob] = useState([]);
  const [sumAfterDelete, setSumAfterDelete] = useState(0);
  const [hoursUpdated, setHoursUpdated] = useState("n");
  const [isLoading, setIsLoading] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [minimize, setMinimize] = useState(true);
  const [jobClass, setJobClass] = useState("");
  const [deletionData, setDeletionData] = useState({
    attributeName: "",
    selectedRow: "",
    filterItem: "",
  });

  const { parFormDataState, parDispatch } = useCurrentPar();

  useAbortEffect((signal) => {
    asyncAPICall(
      `api:E3-qrfOe/vprevailingwageclassByJob?job=${parFormDataState.jobNumber}`,
      "GET",
      null,
      null,
      (data) => {
        setIsLoading(false);
        setPwClassesByJob(() => {
          const workingPwClasses = [
            { Class: "default", Description: "Non Prevailing Wage", id: 0 },
          ];
          workingPwClasses.push(...data);
          return workingPwClasses;
        });
      },
      (err) => {
        setIsLoading(false);
        console.error(err);
      },
      signal
    );
  }, []);

  useEffect(() => {
    const phaseEmployees = parFormDataState.phaseCodes[phaseNum].laborData;
    const currentEmployeeEdit = phaseEmployees.filter(
      (phaseEmployee) => phaseEmployee.id === employeeId
    );

    setClassesSelected(currentEmployeeEdit[0]?.classes || {});
  }, [employeeId, parFormDataState.phaseCodes, phaseNum]);

  const addProgressEntry = async () => {
    const getUomData = new Promise((resolve, reject) => {
      let uomData;
      asyncAPICall(
        `api:W2oHkD04/vtbluombyJobAndPhase?job=${parFormDataState.jobNumber}&phase=${phaseNum}`,
        "GET",
        null,
        null,
        (data) => {
          if (data && data.length > 0) {
            uomData = data;
            resolve(uomData);
          } else {
            setIsProgressEmpty(true);
            errorToast(
              `Job ${parFormDataState.jobNumber} With Phase ${phaseNum} Does Not Have a Progress Entry`
            );
            setIsLoading(false);
          }
        },
        (err) => {
          console.log("Getting UOM Data Error", err);
          setIsLoading(false);
          reject(err);
        }
      );
    });

    getUomData.then((uomData) => {
      const lastIndex =
        parFormDataState.phaseCodes[phaseNum].progressEntry.slice(-1);
      const lastId = lastIndex[0];
      const id = lastId?.id + 1;
      const newId =
        parFormDataState.phaseCodes[phaseNum].progressEntry.length > 0 ? id : 1;

      parDispatch({
        type: "addRecord",
        payload: {
          phaseCode: phaseNum,
          attributeName: "progressEntry",
          newRecords: [
            {
              id: newId,
              job: uomData[0].job,
              phase: uomData[0].phase,
              description: uomData[0].description,
              currentEstimatedUnits: uomData[0].currentEstimatedUnits,
              uom: uomData[0].uom,
              jtdProgress: uomData[0].jtdProgress,
              dcrProgress: "0",
              costType: uomData[0].costType,
            },
          ],
        },
      });
      setIsLoading(false);
    });
  };

  const updatePhaseCodeNotes = (updateValue, fieldName, phase = phaseNum) => {
    parDispatch({
      type: "updatePhaseInput",
      payload: {
        updateValue,
        fieldName,
        phase,
      },
    });
  };

  function updateHoursWorked(sum, id) {
    parDispatch({
      type: "updatePhaseCodeField",
      payload: {
        phaseCode: props.phaseNum,
        attributeName: "laborData",
        fieldName: "hoursWorked",
        updatedValue: sum,
        rowId: id,
        filterName: "id",
      },
    });
    setHoursUpdated("n");
  }

  const updatePhase = (
    updatedValue,
    attributeName,
    fieldName,
    rowId,
    filterName,
    phase = phaseNum
  ) => {
    parDispatch({
      type: "updatePhaseCodeField",
      payload: {
        phaseCode: phase,
        attributeName,
        fieldName,
        updatedValue,
        rowId,
        filterName,
      },
    });
  };

  const updatePhaseObject = (
    updatedValue,
    attributeName,
    fieldName,
    rowId,
    filterName,
    objectKey,
    phase = phaseNum
  ) => {
    parDispatch({
      type: "updatePhaseCodeFieldObject",
      payload: {
        phaseCode: phase,
        attributeName,
        fieldName,
        updatedValue,
        rowId,
        filterName,
        objectKey,
      },
    });
    setHoursUpdated("y");
  };

  const updatePerDiemPhase = (
    updatedValue,
    attributeName,
    fieldName,
    rowId,
    filterName
  ) => {
    parDispatch({
      type: "updatePhaseCodeField",
      payload: {
        phaseCode: "012130-00-00",
        attributeName,
        fieldName,
        updatedValue,
        rowId,
        filterName,
      },
    });
  };

  const clearTooManyHours = (
    attributeName,
    fieldName,
    rowId,
    filterName,
    toastMessage,
    object = false,
    objectKey
  ) => {
    if (object) {
      updatePhaseObject(
        "0",
        attributeName,
        fieldName,
        rowId,
        filterName,
        objectKey
      );
      errorToast(toastMessage);
    } else {
      updatePhase("0", attributeName, fieldName, rowId, filterName);
      errorToast(toastMessage);
    }
  };

  const clearPhase = () => {
    parDispatch({
      type: "clearPhasecode",
      payload: {
        phasecode: phaseNum,
      },
    });
  };

  const deletePhase = () => {
    parDispatch({
      type: "removePhaseCode",
      payload: {
        selectedPhase: phaseNum,
      },
    });

    setIsConfirmationModalOpen(false);

    ref.current.splice(
      ref.current.indexOf(
        ref.current.find((el) => {
          return el?.id === item.phaseDescription;
        })
      ),
      1
    );
  };

  const deleteRow = (attributeName, selectedRecord, filterName) => {
    parDispatch({
      type: "removeRecord",
      payload: {
        phaseCode: phaseNum,
        attributeName,
        selectedRecord,
        filterName,
      },
    });

    let otherRecordsFound = false;

    Object.keys(parFormDataState.phaseCodes).forEach((phaseCode) => {
      if (phaseCode !== "012130-00-00" && phaseCode !== phaseNum) {
        parFormDataState.phaseCodes[phaseCode].laborData.forEach((employee) => {
          if (employee.name === selectedRecord) {
            otherRecordsFound = true;
            return;
          }
        });
      }
    });

    if (otherRecordsFound === false) {
      parDispatch({
        type: "removeRecord",
        payload: {
          phaseCode: "012130-00-00",
          attributeName,
          selectedRecord,
          filterName,
        },
      });
    }
  };

  const deleteClass = (pwClassesByJob, jobClass, employeeId) => {
    const filteredClasses = {};
    for (const key in pwClassesByJob) {
      if (key !== jobClass[0]) {
        filteredClasses[key] = pwClassesByJob[key];
      }
    }

    parDispatch({
      type: "updatePhaseCodeField",
      payload: {
        phaseCode: props.phaseNum,
        attributeName: "laborData",
        fieldName: "classes",
        updatedValue: filteredClasses,
        rowId: employeeId,
        filterName: "id",
      },
    });
  };

  const handleModal = (attributeName, selectedRow, filterItem, type) => {
    setDeletionData({
      attributeName: attributeName,
      selectedRow: selectedRow,
      filterItem: filterItem,
    });

    handleConfirmationModalOpening(type);
  };

  const handleResetPerDiem = (
    perDiemAmount,
    attributeName,
    fieldName,
    rowId,
    filterName
  ) => {
    updatePhase(perDiemAmount, attributeName, fieldName, rowId, filterName);
    errorToast(
      "Error Per Diem Amount, cannot go above the per diem job limit."
    );
  };

  const phaseModalChildren = {
    addClass: (
      <AddClassForm
        pwClassesByJob={pwClassesByJob}
        isLoading={isLoading}
        phaseNum={phaseNum}
        parFormDataState={parFormDataState}
        employeeId={employeeId}
        classesSelected={classesSelected}
        setClassesSelected={setClassesSelected}
      />
    ),
    addEmployee: (
      <AddEmployee
        phaseNum={phaseNum}
        perDiem={perDiem}
        perDiemAmount={perDiemAmount}
        closeModal={() => setIsPhaseModalOpen(false)}
      />
    ),
    addEquipment: (
      <EquipmentForm
        setIsPhaseModalOpen={setIsPhaseModalOpen}
        hoursWorked={"0"}
        phaseNum={phaseNum}
        closeModal={() => setIsPhaseModalOpen(false)}
      />
    ),
  };

  const phaseConfirmationModalInfo = {
    deletePhase: {
      modalQuestion: `Are you sure you want to remove: ${
        item.phaseDescription?.length > 0
          ? item.phaseDescription
          : `"untitled" `
      }?`,
      onConfirm: deletePhase,
    },
    deleteEmployee: {
      modalQuestion: `Are you sure you want to remove: ${
        deletionData.selectedRow.length > 0
          ? deletionData.selectedRow
          : `"untitled" `
      }?`,
      onConfirm: () => {
        deleteRow(
          deletionData.attributeName,
          deletionData.selectedRow,
          deletionData.filterItem
        );
        setIsConfirmationModalOpen(false);
      },
    },
    deleteEquipment: {
      modalQuestion: `Are you sure you want to remove: ${"\n"}
      ${
        deletionData.selectedRow.length > 0
          ? deletionData.selectedRow
          : `"untitled" `
      }?`,
      onConfirm: () => {
        deleteRow(
          deletionData.attributeName,
          deletionData.selectedRow,
          deletionData.filterItem
        );
        setIsConfirmationModalOpen(false);
      },
    },
    deleteProgressEntry: {
      modalQuestion: `Are you sure you want to remove: ID #${
        deletionData.selectedRow > 0 ? deletionData.selectedRow : `"untitled" `
      }?`,
      onConfirm: () => {
        deleteRow(
          deletionData.attributeName,
          deletionData.selectedRow,
          deletionData.filterItem
        );
        setIsConfirmationModalOpen(false);
      },
    },
  };

  const handleModalOpening = (modalType) => {
    setIsPhaseModalOpen(true);
    setModalDescription(modalType);
  };

  const handleConfirmationModalOpening = (modalType) => {
    setModalDescription(modalType);
    setIsConfirmationModalOpen(true);
  };

  const isProgressEntryDisabled = () =>
    isLoading ||
    parFormDataState.phaseCodes[phaseNum].progressEntry.length > 0 ||
    isProgressEmpty;

  return (
    <div
      id={item.phaseDescription}
      className={`par-phase-container ${minimize ? "" : "is-selected"}`}
      ref={(el) => {
        if (!ref.current?.includes(el)) {
          ref.current.push(el);
        }
      }}
    >
      <div className="phase-button-container">
        <button
          className={`phase-code-button ${minimize ? "" : "is-selected"}`}
          onClick={() => setMinimize((minimize) => !minimize)}
          title={item.phaseDescription}
        >
          Phase: {item.phaseDescription}
        </button>

        <button
          className="remove-modal"
          onClick={() => handleConfirmationModalOpening("deletePhase")}
        >
          <FontAwesomeIcon icon="fa fa-trash" />
        </button>
      </div>

      <div className={`${minimize ? "hide-table" : ""}`}>
        <button
          className="header-button"
          onClick={() => setCollapsePhaseNote((prev) => !prev)}
        >
          Phase Note
        </button>

        <div
          className={`note-container ${
            collapsePhaseNote ? "hide-note-container" : ""
          }`}
        >
          <ParTextArea
            customClass="phase-notes"
            rows="10"
            cols="200"
            labelName={`${phaseNum}-phase-notes`}
            labelClass="hidden"
            placeholder="Phase Notes: Please record any important information that pertains specifically to this phase code. Who, What, Where, When, and How."
            value={item.phaseNote}
            onChange={(e) => updatePhaseCodeNotes(e.target.value, "phaseNote")}
          />
        </div>
      </div>
      <div className={`${minimize ? "hide-table" : ""}`}>
        <button
          className="header-button"
          onClick={() => setCollapseEmployee((prev) => !prev)}
        >
          Labor Data
        </button>

        <div
          className={`table-container ${
            collapseEmployee ? "hide-table-container" : ""
          }`}
        >
          {parFormDataState.jobCertified !== "Y" ||
          phaseNum === "012130-00-00" ? (
            <ParTable
              tableHeaders={[
                { name: "Employee Name", class: "padding-20" },
                { name: "Employee #", class: "" },
                { name: "Hours Worked", class: "" },
                phaseNum === "012130-00-00" && {
                  name: "Per Diem Y/N",
                  class: "",
                },
                phaseNum === "012130-00-00" && {
                  name: "Per Diem Rate",
                  class: "",
                },
                { name: "Labor Note", class: "" },
              ]}
              tableRows={renderEmployees(
                parFormDataState,
                phaseNum,
                updatePhase,
                handleModal,
                clearTooManyHours,
                perDiemAmount,
                handleResetPerDiem,
                updatePerDiemPhase
              )}
            />
          ) : (
            <PrevailingWagesTable
              parFormDataState={parFormDataState}
              phaseNum={phaseNum}
              setEmployeeId={setEmployeeId}
              setEmployeeClasses={setEmployeeClasses}
              setJobClass={setJobClass}
              setHoursUpdated={setHoursUpdated}
              hoursUpdated={hoursUpdated}
              pwClassesByJob={pwClassesByJob}
              editInfo={true}
              clearTooManyHours={clearTooManyHours}
              handleModal={handleModal}
              updateHoursWorked={updateHoursWorked}
              setSumAfterDelete={setSumAfterDelete}
              setIsPrevailingWageModalOpen={setIsPrevailingWageModalOpen}
              setIsDeletePrevailingWageModalOpen={
                setIsDeletePrevailingWageModalOpen
              }
            />
          )}

          {parFormDataState.phaseCodes[phaseNum]?.laborData?.length > 0 && (
            <h3 className="par-phase-total">
              Total Employee Hours:{" "}
              {phaseNum === "012130-00-00"
                ? calculateTotalPerDiemHours(
                    parFormDataState.phaseCodes[phaseNum].laborData,
                    parFormDataState
                  )
                : calculateSectionValues(
                    parFormDataState.phaseCodes[phaseNum].laborData,
                    "hoursWorked"
                  )}
            </h3>
          )}

          <button
            className="add-button"
            onClick={() => handleModalOpening("addEmployee")}
          >
            Add Employee
          </button>
        </div>
      </div>
      {phaseNum !== "012130-00-00" && (
        <div className={`${minimize ? "hide-table" : ""}`}>
          <button
            className="header-button"
            onClick={() => setCollapseEquipment((prev) => !prev)}
          >
            Equipment Data
          </button>

          <div
            className={`table-container ${
              collapseEquipment ? "hide-table-container" : ""
            }`}
          >
            <ParTable
              tableHeaders={[
                { name: "Equipment #", class: "padding-20" },
                { name: "Operated Hours (Not Billable)", class: "" },
                { name: "Equipment Note", class: "" },
              ]}
              tableRows={renderEquipmentData(
                parFormDataState,
                phaseNum,
                updatePhase,
                handleModal,
                clearTooManyHours
              )}
            />

            {parFormDataState.phaseCodes[phaseNum].equipmentData.length > 0 && (
              <h3 className="par-phase-total">
                Total Equipment Hours:{" "}
                {calculateSectionValues(
                  parFormDataState.phaseCodes[phaseNum].equipmentData,
                  "operatedHours"
                )}
              </h3>
            )}

            <button
              className="add-button"
              onClick={() => handleModalOpening("addEquipment")}
            >
              Add Equipment
            </button>
          </div>
        </div>
      )}
      {phaseNum !== "012130-00-00" && (
        <div className={`${minimize ? "hide-table" : ""}`}>
          <button
            className="header-button"
            onClick={() => setCollapseProgressEntry((c) => !c)}
          >
            Progress Entry
          </button>

          <div
            className={`table-container ${
              collapseProgressEntry ? "hide-table-container" : ""
            }`}
          >
            <ParTable
              phaseNum={phaseNum}
              tableHeaders={[
                { name: "ID", class: "padding-20" },
                { name: "Phase", class: "" },
                { name: "Description", class: "" },
                { name: "Current Estimated Units", class: "" },
                { name: "UOM", class: "" },
                { name: "JTD Progress", class: "" },
                { name: "PAR Daily Progress", class: "" },
              ]}
              tableRows={renderProgressEntries(
                parFormDataState,
                phaseNum,
                updatePhase,
                handleModal
              )}
            />

            <button
              className="add-button"
              disabled={isProgressEntryDisabled()}
              onClick={() => {
                addProgressEntry(progressEntry, setProgressEntry);
                setIsLoading(true);
              }}
            >
              {isLoading ? "Loading Progress Data" : "Add Progress Entry"}
            </button>
          </div>
        </div>
      )}
      <div className={`button-container ${minimize ? "hide-table" : ""}`}>
        <button
          className="minimize-button"
          onClick={() => setMinimize((m) => !m)}
        >
          Minimize
        </button>

        <button className="clear-button" onClick={clearPhase}>
          Clear
        </button>
      </div>

      <Modal
        isModalOpen={isPhaseModalOpen}
        onRequestClose={() => setIsPhaseModalOpen(false)}
      >
        {phaseModalChildren[modalDescription]}
      </Modal>

      {Object.keys(phaseConfirmationModalInfo).includes(modalDescription) && (
        <ConfirmationModal
          isModalOpen={isConfirmationModalOpen}
          onRequestClose={() => setIsConfirmationModalOpen(false)}
          onConfirm={phaseConfirmationModalInfo[modalDescription].onConfirm}
          modalQuestion={
            phaseConfirmationModalInfo[modalDescription].modalQuestion
          }
        />
      )}

      <ConfirmationModal
        isModalOpen={isDeletePrevailingWageModalOpen}
        onRequestClose={() => setIsDeletePrevailingWageModalOpen(false)}
        modalQuestion={`Are you sure you want to remove: ${
          getDescriptionFromClass(jobClass[0], pwClassesByJob)?.Description
        }?`}
        onConfirm={() => {
          deleteClass(employeeClasses, jobClass, employeeId);
          updateHoursWorked(sumAfterDelete, employeeId);
          setIsDeletePrevailingWageModalOpen(false);
        }}
      />

      <Modal
        isModalOpen={isPrevailingWageModalOpen}
        onRequestClose={() => setIsPrevailingWageModalOpen(false)}
      >
        <AddClassForm
          pwClassesByJob={pwClassesByJob}
          isLoading={isLoading}
          phaseNum={phaseNum}
          parFormDataState={parFormDataState}
          employeeId={employeeId}
          classesSelected={classesSelected}
          setClassesSelected={setClassesSelected}
          setIsPrevailingWageModalOpen={setIsPrevailingWageModalOpen}
        />
      </Modal>
    </div>
  );
});

export default PhaseCodeItem;
