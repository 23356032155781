import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import VerificationPhasecode from "../components/VerificationPhasecode";
import VerificationTotals from "../components/VerificationTotals";
import { useCurrentPar } from "../components/contexts/CurrentParContext";
import asyncAPICall from "../util/apiWrapper";
import { baseParFormData } from "../components/reducer/store/baseParFormData";
import useAbortEffect from "../components/hooks/useAbortEffect";
import ApproverChanges from "../components/ApproverChanges";
import LetterHead from "../img/letter-head.svg";
import ConfirmationModal from "../components/modals/confirmation-modal/ConfirmationModal";
import Truncate from "../components/helpers/Truncate";
import { useModalContext } from "../components/contexts/ModalContext";

const SelectPrintPage = (props) => {
  const { parFormDataState } = useCurrentPar();
  const [parData, setParData] = useState(baseParFormData);
  const [foreman, setForeman] = useState("");
  const [pfs, setPfs] = useState("");
  const [jobNumDesc, setJobNumDesc] = useState("");
  const [combinedCheckboxValues, setCombinedCheckboxValues] = useState([]);
  const [phaseTables, setPhaseTables] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isPrinting, setIsPrinting] = useState(0);
  const [pageBlur, setPageBlur] = useState(false);

  const { isModalOpen, handleOpenModal, handleCloseModal } = useModalContext();

  const windowWidthRef = useRef(window.innerWidth);

  const prevPath = props.history.location.state?.prevPath
    ? props.history.location.state.prevPath
    : "/overview";
  const approvalsCheckmarks = props.history.location.state?.approvalsCheckmarks
    ? props.history.location.state.approvalsCheckmarks
    : {};

  const [checkboxValues, setCheckboxValues] = useState({
    jobInfo: true,
    pfsJournal: true,
    foremanJournal: true,
    forecastWork: true,
    issues: true,
    subcontractor: true,
    timeMaterials: true,
    material: true,
    changes: true,
    signatures: true,
    totals: true,
  });

  const [dailyTicket, setDailyTicket] = useState({
    isDailyTicket: false,
    offValues: {
      ...checkboxValues,
    },
  });

  const viewId = props.match.params.slug;

  const changePrint = useCallback((updateField, index) => {
    setCombinedCheckboxValues((prevCheckboxValue) =>
      prevCheckboxValue.map((checkboxObj) => {
        if (checkboxObj.index === index) {
          return { ...checkboxObj, [updateField]: !checkboxObj[updateField] };
        } else {
          return checkboxObj;
        }
      })
    );
  }, []);

  const handlePrintCheck = (type) => {
    const newCheckboxValues = { ...checkboxValues };

    newCheckboxValues[type] = !newCheckboxValues[type];

    setCheckboxValues(newCheckboxValues);
  };

  const handleDailyCheck = () => {
    if (!dailyTicket.isDailyTicket) {
      setCheckboxValues({
        jobInfo: true,
        pfsJournal: true,
        foremanJournal: false,
        forecastWork: false,
        issues: false,
        subcontractor: true,
        timeMaterials: true,
        material: true,
        changes: false,
        signatures: true,
        totals: true,
      });

      setCombinedCheckboxValues((prev) =>
        prev.map((checkbox) => ({ index: checkbox.index, phase: true }))
      );
    } else {
      setCheckboxValues({
        jobInfo: true,
        pfsJournal: true,
        foremanJournal: true,
        forecastWork: true,
        issues: true,
        subcontractor: true,
        timeMaterials: true,
        material: true,
        changes: true,
        signatures: true,
        totals: true,
      });
    }
    setDailyTicket((dT) => {
      return { ...dT, isDailyTicket: !dT.isDailyTicket };
    });
  };

  const handleSubmit = () => {
    const internalInfo =
      checkboxValues.foremanJournal ||
      checkboxValues.forecastWork ||
      checkboxValues.issues ||
      (checkboxValues.changes && parData.statusComments?.length > 0);

    if (internalInfo) {
      handleOpenModal("dailyTicket");
    } else {
      handlePrint();
    }
  };

  useEffect(() => {
    const checkboxList = [];

    const movePerDiem = Object.keys(parData.phaseCodes).filter(
      (phaseCode) => phaseCode !== "012130-00-00"
    );

    const perdiemExists = Object.keys(parData.phaseCodes).some(
      (phaseCode) => phaseCode === "012130-00-00"
    );

    if (perdiemExists) movePerDiem.push("012130-00-00");

    for (let index in movePerDiem) {
      if (movePerDiem[index] === "012130-00-00") {
        checkboxList.push({
          index: Number(index),
          phase: true,
        });
      } else {
        checkboxList.push({
          index: Number(index),
          phase: true,
        });
      }
    }

    setCombinedCheckboxValues(checkboxList);
  }, [parData.phaseCodes]);

  useEffect(() => {
    const movePerDiem = Object.keys(parData.phaseCodes).filter(
      (phaseCode) => phaseCode !== "012130-00-00"
    );

    const perdiemExists = Object.keys(parData.phaseCodes).some(
      (phaseCode) => phaseCode === "012130-00-00"
    );

    if (perdiemExists) movePerDiem.push("012130-00-00");

    setPhaseTables(
      movePerDiem.map((phaseNum, index) => {
        return (
          <div
            className={`${
              combinedCheckboxValues[index]?.phase ? "" : "hide-print"
            }`}
            key={phaseNum}
          >
            <VerificationPhasecode
              index={index}
              phaseNum={phaseNum}
              parData={parData}
              view={"print"}
              changeDisabledSubmit={changePrint}
              combinedCheckboxValues={combinedCheckboxValues}
              isDailyTicket={dailyTicket.isDailyTicket}
              windowWidth={windowWidth}
            />

            <div className="break-wrapper">
              <div className="checkbox" />
              <hr
                className={`${
                  combinedCheckboxValues[index]?.phase ? "" : "hide-print"
                }`}
              />
            </div>
          </div>
        );
      })
    );
  }, [
    changePrint,
    parData,
    combinedCheckboxValues,
    dailyTicket.isDailyTicket,
    checkboxValues.phases,
    windowWidth,
  ]);

  const renderField = (categories, id, renderCell) => {
    return categories.map((category, idx) => (
      <td className={idx === 0 ? "first-column" : ""} key={category[id]}>
        {renderCell(category)}
      </td>
    ));
  };

  const renderSubcontractors = () => {
    if (windowWidth <= 650) {
      return (
        <div className="table-wrapper">
          {parData.subcontractor[0]?.name === "none" ? (
            <table>
              <tbody>
                <tr>
                  <th className="vertical-header">
                    <span>Subcontractors</span>
                  </th>
                  <td className="first-column">
                    None of our subcontractors were observed this period.
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table>
              <tbody>
                <tr>
                  <th className="vertical-header">
                    <span>Subcontractor</span>
                  </th>
                  {renderField(
                    parData.subcontractor,
                    "id",
                    (subcontractor) => subcontractor.name
                  )}
                </tr>
                <tr>
                  <th className="vertical-header">
                    <span>Description</span>
                  </th>
                  {renderField(
                    parData.subcontractor,
                    "id",
                    (subcontractor) => subcontractor.description
                  )}
                </tr>
                <tr>
                  <th className="vertical-header">
                    <span>Hours</span>
                  </th>
                  {renderField(
                    parData.subcontractor,
                    "id",
                    (subcontractor) => subcontractor.dailyHours
                  )}
                </tr>
                <tr>
                  <th className="vertical-header">
                    <span>Count</span>
                  </th>
                  {renderField(
                    parData.subcontractor,
                    "id",
                    (subcontractor) => subcontractor.employeeCount
                  )}
                </tr>
              </tbody>
            </table>
          )}
        </div>
      );
    } else {
      return (
        <div className="table-wrapper">
          {parData.subcontractor[0]?.name === "none" ? (
            <table>
              <thead>
                <tr>
                  <th>Subcontractors</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>None of our subcontractors were observed this period.</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Subcontractor</th>
                  <th>Description</th>
                  <th>Hours</th>
                  <th>Count</th>
                </tr>
              </thead>

              <tbody>
                {parData.subcontractor.map((subcontractor) => {
                  return (
                    <tr key={subcontractor.id}>
                      <td>{subcontractor.name}</td>
                      <td>{subcontractor.description}</td>
                      <td>{subcontractor.dailyHours}</td>
                      <td>{subcontractor.employeeCount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      );
    }
  };

  const renderIssues = () => {
    if (windowWidth <= 650) {
      return (
        <div className="table-wrapper">
          {parData.idb[0]?.issueType === "No Issues Entered" ? (
            <table>
              <tbody>
                <tr>
                  <th className="vertical-header">
                    <span>Issues</span>
                  </th>
                  <td className="first-column">No Issues Entered</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table>
              <tbody>
                <tr>
                  <th className="vertical-header">
                    <span>Issue Type</span>
                  </th>
                  {renderField(
                    parData.idb,
                    "issueNumber",
                    (issue) => issue.issueType
                  )}
                </tr>
                <tr>
                  <th className="vertical-header">
                    <span>Date</span>
                  </th>
                  {renderField(
                    parData.idb,
                    "issueNumber",
                    (issue) => issue.dateRaised
                  )}
                </tr>
                <tr>
                  <th className="vertical-header">
                    <span>Description</span>
                  </th>
                  {renderField(
                    parData.idb,
                    "issueNumber",
                    (issue) => issue.description
                  )}
                </tr>
              </tbody>
            </table>
          )}
        </div>
      );
    } else {
      return (
        <div className="table-wrapper">
          {parData.idb[0]?.issueType === "No Issues Entered" ? (
            <table>
              <thead>
                <tr>
                  <th>Issues</th>
                </tr>
              </thead>
              <tbody>
                <tr className="issues-rows">
                  <td>No Issues Entered</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Issue Type</th>
                  <th>Date</th>
                  <th>Description</th>
                </tr>
              </thead>

              <tbody>
                {parData.idb.map((issue) => {
                  return (
                    <tr className="issues-rows" key={issue.issueNumber}>
                      <td className="issue-type">{issue.issueType}</td>
                      <td className="date-raised">{issue.dateRaised}</td>
                      <td>{issue.description}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      );
    }
  };

  const renderTimeMaterials = () => {
    if (windowWidth <= 650) {
      return (
        <div className="table-wrapper">
          {parData.matCustomer === "" ? (
            <table>
              <tbody>
                <tr>
                  <th className="vertical-header">
                    <span>T&M Customer</span>
                  </th>
                  <td className="first-column">
                    None of our time and material customers were observed this
                    period.
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table>
              <tbody>
                <tr>
                  <th className="vertical-header">
                    <span>T&M Customer</span>
                  </th>
                  <td className="first-column">{parData.matCustomer}</td>
                </tr>
                <tr>
                  <th className="vertical-header">
                    <span>Customer Code</span>
                  </th>
                  <td className="first-column">{parData.matCustomerCode}</td>
                </tr>
                <tr>
                  <th className="vertical-header">
                    <span>Onsite Representative</span>
                  </th>
                  <td className="first-column">{parData.matOsr}</td>
                </tr>
                <tr>
                  <th className="vertical-header">
                    <span>Jobsite</span>
                  </th>
                  <td className="first-column">{parData.matJobsite}</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      );
    } else {
      return (
        <div className="table-wrapper">
          {parData.matCustomer === "" ? (
            <table>
              <thead>
                <tr>
                  <th>T&M Customer</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    None of our time and material customers were observed this
                    period.
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>T&M Customer</th>
                  <th>Customer Code</th>
                  <th>Onsite Representative</th>
                  <th>Jobsite</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{parData.matCustomer}</td>
                  <td>{parData.matCustomerCode}</td>
                  <td>{parData.matOsr}</td>
                  <td>{parData.matJobsite}</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      );
    }
  };

  const renderMaterials = () => {
    if (windowWidth <= 650) {
      return (
        <div className="table-wrapper">
          {parData.material[0]?.phaseCode === "" ? (
            <table>
              <tbody>
                <tr>
                  <th className="vertical-header">
                    <span>Materials</span>
                  </th>
                  <td className="first-column">
                    None of our materials were observed this period.
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table>
              <tbody>
                <tr>
                  <th className="vertical-header">
                    <span>Material</span>
                  </th>
                  {renderField(
                    parData.material,
                    "id",
                    (material) => material.description
                  )}
                </tr>
                <tr>
                  <th className="vertical-header">
                    <span>Units</span>
                  </th>
                  {renderField(parData.material, "id", (material) => {
                    return `${material.units} ${material.uom}`;
                  })}
                </tr>
                <tr>
                  <th className="vertical-header">
                    <span>Phase Code</span>
                  </th>
                  {renderField(
                    parData.material,
                    "id",
                    (material) => material.phaseCode
                  )}
                </tr>
                <tr>
                  <th className="vertical-header">
                    <span>Phase Description</span>
                  </th>
                  {renderField(
                    parData.material,
                    "id",
                    (material) => material.phaseDesc
                  )}
                </tr>
              </tbody>
            </table>
          )}
        </div>
      );
    } else {
      return (
        <div className="table-wrapper">
          {parData.material[0]?.phaseCode === "" ? (
            <table>
              <thead>
                <tr>
                  <th>Materials</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>None of our materials were observed this period.</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Material</th>
                  <th>Units</th>
                  <th>Phase Code</th>
                  <th>Phase Description</th>
                </tr>
              </thead>

              <tbody>
                {parData.material.map((material) => {
                  return (
                    <tr key={material.id}>
                      <td>{material.description}</td>
                      <td>
                        {material.units} {material.uom}
                      </td>
                      <td>{material.phaseCode}</td>
                      <td>{material.phaseDesc}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      );
    }
  };

  const delay = useCallback(
    () =>
      setTimeout(() => {
        setIsPrinting(1);
      }, 500),
    []
  );

  const handlePrint = () => {
    handleCloseModal("dailyTicket");
    setPageBlur(true);
    delay();

    return setWindowWidth((prev) => {
      if (prev < 651) {
        return 651;
      } else {
        return prev;
      }
    });
  };

  useEffect(() => {
    if (isPrinting === 1 && windowWidth > 650) {
      window.print();
      setIsPrinting(2);
    }

    return () => clearTimeout(delay);
  }, [isPrinting, windowWidth, delay]);

  useEffect(() => {
    if (isPrinting === 2) {
      setWindowWidth(windowWidthRef.current);
      setIsPrinting(0);
      setPageBlur(false);
    }
  }, [isPrinting]);

  useAbortEffect(
    (signal) => {
      if (viewId) {
        const getParReportPromise = new Promise((resolve, reject) => {
          asyncAPICall(
            `api:W2oHkD04/partblmain/parNumber?parNumber=${viewId}`,
            "GET",
            null,
            null,
            (data) => {
              setParData(data);
              resolve(true);
            },
            (err) => {
              if (!signal.aborted) {
                reject("Error fetching PAR report", err);
              }
            },
            signal
          );
        });

        getParReportPromise.catch(console.error);
      } else {
        setParData(parFormDataState);
      }
    },
    [viewId, parFormDataState]
  );

  useAbortEffect(
    (signal) => {
      if (parData.jobNumber) {
        const getForemanPromise = new Promise((resolve, reject) => {
          //foreman 1111 does not currently exist
          if (parData.foreman !== 1111) {
            asyncAPICall(
              `api:KE7MCNYf/vEmployeesNoSpacesIdByID?employeeID=${parData.foreman}`,
              "GET",
              null,
              null,
              (data) => {
                if (data) {
                  const fullName = `${data[0].FirstName} ${data[0].MidName} ${data[0].LastName}`;
                  setForeman(fullName);
                  resolve(true);
                }
              },
              (err) => {
                if (!signal.aborted) {
                  reject("error getting Foreman", err);
                }
              },

              signal
            );
          }
        });

        const getPfsPromise = new Promise((resolve, reject) => {
          asyncAPICall(
            `api:KE7MCNYf/vEmployeesNoSpacesIdByID?employeeID=${parData.pfs}`,
            "GET",
            null,
            null,
            (data) => {
              if (data.length) {
                const fullName = `${data[0].FirstName} ${data[0].MidName} ${data[0].LastName}`;
                setPfs(fullName);
                resolve(true);
              }
            },
            (err) => {
              if (!signal.aborted) {
                reject("error getting PFS", err);
              }
            },
            signal
          );
        });

        const getParJobPromise = new Promise((resolve, reject) => {
          asyncAPICall(
            `api:KE7MCNYf/vprojectswithid_by_Project?project=${parData.jobNumber}`,
            "GET",
            null,
            null,
            (data) => {
              if (data.length) {
                const jobNameDescription = `${data[0].Project} ${data[0].Project_Name}`;
                setJobNumDesc(jobNameDescription);
                resolve(true);
              }
            },
            (err) => {
              if (!signal.aborted) {
                reject("Getting Jobs Error", err);
              }
            }
          );
        });

        Promise.all([getForemanPromise, getPfsPromise, getParJobPromise]).catch(
          console.error
        );
      }
    },
    [parData]
  );

  useEffect(() => window.scrollTo(0, 0), []);

  const handleWindowResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
    windowWidthRef.current = window.innerWidth;
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  return (
    <div
      className={`verification-page-container select-print ${
        pageBlur && windowWidth <= 651 ? "blur" : ""
      }`}
    >
      <div className="daily-ticket-container hide-print">
        <p>Select This Checkbox for Daily Ticket Fields</p>

        <label className="checkbox container">
          <input
            checked={dailyTicket.isDailyTicket}
            className="checkbox"
            type="checkbox"
            onChange={handleDailyCheck}
          />
          <span className="checkmark job-info-checkbox" />
        </label>
      </div>

      <div className="header-wrapper">
        <div className="break-wrapper">
          <div className="checkbox" />
        </div>

        <div className="header">
          <div className="letter-head">
            <img src={LetterHead} alt="bhi letterhead" />
          </div>
          <div className="top-header print-select">
            {"Select Which Section to Print"}
          </div>

          <div className="top-header show-print">{"Daily Work Ticket"}</div>
        </div>
      </div>

      <div className="break-wrapper">
        <div className="checkbox" />
      </div>

      <div
        className={`section-one ${checkboxValues.jobInfo ? "" : "hide-print"}`}
      >
        <label className="checkbox container">
          <input
            checked={checkboxValues.jobInfo}
            className="checkbox"
            type="checkbox"
            onChange={() => handlePrintCheck("jobInfo")}
            disabled={dailyTicket.isDailyTicket}
          />
          <span className="checkmark job-info-checkbox" />
        </label>

        <div className="section-content">
          <div className="upper-content">
            <div className="section-grid">
              <div className="grid-row">
                <div className="data-label">Date: </div>
                <div className="data">
                  {parData.date.length > 0 ? parData.date : "--"}
                </div>
              </div>

              <div className="grid-row">
                <div className="data-label">Temp High: </div>
                <div className="data">
                  {parData.temperatureHigh.length > 0
                    ? `${parData.temperatureHigh}\xB0`
                    : "--"}
                </div>
              </div>

              <div className="grid-row">
                <div className="data-label">Temp Low: </div>
                <div className="data">
                  {parData.temperatureLow.length > 0
                    ? `${parData.temperatureLow}\xB0`
                    : "--"}
                </div>
              </div>

              <div className="grid-row">
                <div className="data-label">Precipitation: </div>
                <div className="data precipitation-data">
                  {parData.precipitation.length > 0
                    ? parData.precipitation
                    : "--"}
                </div>
              </div>

              <div className="grid-row">
                <div className="data-label">Wind: </div>
                <div className="data wind-data">
                  {parData.wind.length > 0 ? parData.wind : "--"}
                </div>
              </div>
            </div>

            <div className="section-grid">
              <div className="grid-row">
                <div className="data-label">Start Time: </div>
                <div className="data">
                  {parData?.startTime.length > 0 ? parData.startTime : "--"}
                </div>
              </div>

              <div className="grid-row">
                <div className="data-label">Stop Time: </div>
                <div className="data">
                  {parData?.stopTime.length > 0 ? parData.stopTime : "--"}
                </div>
              </div>

              <div className="grid-row">
                <div className="data-label">Approver: </div>
                <div className="data">{pfs !== "" ? pfs : "--"}</div>
              </div>

              <div className="grid-row">
                <div className="data-label">Submitter: </div>
                <div className="data">{foreman !== "" ? foreman : "--"}</div>
              </div>

              <div className="grid-row">
                <div className="data-label">Safety Topic: </div>
                <div className="data">
                  <Truncate
                    content={
                      parData.safetyTopic.length > 0
                        ? parData.safetyTopic
                        : "--"
                    }
                    characters={30}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="lower-content">
            <div className="section-grid">
              <div className="grid-row">
                <div className="data-label">Job Number: </div>
                <div className="data">
                  {parData.jobNumber.length > 0 ? jobNumDesc : "--"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="break-wrapper">
        <div className="checkbox" />
        <hr className={`${checkboxValues.jobInfo ? "" : "hide-print"}`} />
      </div>

      <div
        className={`general-notes ${
          checkboxValues.pfsJournal ? "" : "hide-print"
        }`}
      >
        <div className="break-wrapper">
          <label className="checkbox container">
            <input
              className="checkbox"
              checked={checkboxValues.pfsJournal}
              type="checkbox"
              onChange={() => handlePrintCheck("pfsJournal")}
              disabled={dailyTicket.isDailyTicket}
            />
            <span className="checkmark job-info-checkbox" />
          </label>
        </div>

        <div className="section-content">
          <div className="label">Daily Journal</div>
          <div className="details">
            {parData.notes?.length > 0 ? parData.notes : "--"}
          </div>
        </div>
      </div>

      <div className="break-wrapper">
        <div className="checkbox" />
        <hr
          className={`${checkboxValues.foremanJournal ? "" : "hide-print"}`}
        />
      </div>

      <div
        className={`general-notes ${
          checkboxValues.foremanJournal ? "" : "hide-print"
        }`}
      >
        <div className="break-wrapper">
          <label className="checkbox container">
            <input
              checked={checkboxValues.foremanJournal}
              className="checkbox"
              type="checkbox"
              onChange={() => handlePrintCheck("foremanJournal")}
              disabled={dailyTicket.isDailyTicket}
            />
            <span className="checkmark job-info-checkbox" />
          </label>
        </div>

        <div className="section-content">
          <div className="label daily-journal">Foreman Daily Journal</div>
          <div className="details">
            {parData.workPreformed?.length > 0 ? parData.workPreformed : "--"}
          </div>
        </div>
      </div>

      <div className="break-wrapper">
        <div className="checkbox" />
        <hr className={`${checkboxValues.forecastWork ? "" : "hide-print"}`} />
      </div>

      <div
        className={`general-notes ${
          checkboxValues.forecastWork ? "" : "hide-print"
        }`}
      >
        <div className="break-wrapper">
          <label className="checkbox container">
            <input
              className="checkbox"
              checked={checkboxValues.forecastWork}
              type="checkbox"
              onChange={() => handlePrintCheck("forecastWork")}
              disabled={dailyTicket.isDailyTicket}
            />
            <span className="checkmark job-info-checkbox" />
          </label>
        </div>

        <div className="section-content">
          <div className="label">Foreman Forecasted Work</div>
          <div className="details">
            {parData.workForecasted?.length > 0 ? parData.workForecasted : "--"}
          </div>
        </div>
      </div>

      <div className="break-wrapper">
        <div className="checkbox" />
        <hr
          className={`${
            checkboxValues.forecastWork ||
            checkboxValues.foremanJournal ||
            checkboxValues.pfsJournal
              ? ""
              : "hide-print"
          }`}
        />
      </div>

      <div
        className={`${
          checkboxValues.issues
            ? "section-wrapper"
            : "section-wrapper hide-print"
        }`}
      >
        <label className="checkbox container">
          <input
            className="checkbox"
            checked={checkboxValues.issues}
            type="checkbox"
            onChange={() => handlePrintCheck("issues")}
            disabled={dailyTicket.isDailyTicket}
          />
          <span className="checkmark" />
        </label>

        {renderIssues()}
      </div>

      <div className="break-wrapper">
        <div className="checkbox" />
      </div>

      <div className="break-wrapper">
        <div className="checkbox" />
      </div>

      <div
        className={`section-wrapper ${
          checkboxValues.subcontractor ? "" : "hide-print"
        }`}
      >
        <label className="checkbox container">
          <input
            className="checkbox"
            checked={checkboxValues.subcontractor}
            type="checkbox"
            onChange={() => handlePrintCheck("subcontractor")}
            disabled={dailyTicket.isDailyTicket}
          />
          <span className="checkmark" />
        </label>

        {parData.subcontractor.length > 0 ? renderSubcontractors() : "--"}
      </div>

      <div className="break-wrapper">
        <div className="checkbox" />
      </div>

      <div
        className={`section-wrapper ${
          checkboxValues.timeMaterials ? "" : "hide-print"
        }`}
      >
        <label className="checkbox container">
          <input
            className="checkbox"
            checked={checkboxValues.timeMaterials}
            type="checkbox"
            onChange={() => handlePrintCheck("timeMaterials")}
            disabled={dailyTicket.isDailyTicket}
          />
          <span className="checkmark" />
        </label>

        {renderTimeMaterials()}
      </div>

      <div className="break-wrapper">
        <div className="checkbox" />
      </div>

      <div
        className={`section-wrapper ${
          !checkboxValues.material ? "hide-print" : ""
        }`}
      >
        <label className="checkbox container">
          <input
            className="checkbox"
            checked={checkboxValues.material}
            type="checkbox"
            onChange={() => handlePrintCheck("material")}
            disabled={dailyTicket.isDailyTicket}
          />
          <span className="checkmark" />
        </label>

        {renderMaterials()}
      </div>

      <div className="break-wrapper section-three-seperator">
        <div className="checkbox" />
        <hr className={`${checkboxValues.totals ? "" : "hide-print"}`} />
      </div>

      <div className="section-three">{phaseTables}</div>

      <div className={`totals ${checkboxValues.totals ? "" : "hide-print"}`}>
        <label className="checkbox container">
          <input
            className="checkbox"
            checked={checkboxValues.totals}
            type="checkbox"
            onChange={() => handlePrintCheck("totals")}
            disabled={dailyTicket.isDailyTicket}
          />
          <span className="checkmark" />
        </label>

        <VerificationTotals formData={parData} windowWidth={windowWidth} />
      </div>

      {parData.statusComments?.length > 0 && (
        <div className="totals">
          <label className="checkbox container">
            <input
              className="checkbox"
              checked={checkboxValues.changes}
              type="checkbox"
              onChange={() => handlePrintCheck("changes")}
              disabled={dailyTicket.isDailyTicket}
            />
            <span className="checkmark" />
          </label>
          <div
            className={`tables ${checkboxValues.changes ? "" : "hide-print"}`}
          >
            <ApproverChanges parData={parData} color={"black"} />
          </div>
        </div>
      )}
      <div className="break-wrapper">
        <div className="checkbox" />
        <hr className={`${checkboxValues.timeMaterials ? "" : "hide-print"}`} />
      </div>

      <div
        className={`section-signature ${
          !checkboxValues.signatures ? "hide-print" : ""
        }`}
      >
        <label className="checkbox container">
          <input
            className="checkbox"
            checked={checkboxValues.signatures}
            type="checkbox"
            onChange={() => handlePrintCheck("signatures")}
            disabled={dailyTicket.isDailyTicket}
          />
          <span className="checkmark" />
        </label>

        <div className="show-mobile hide-print">
          Show date and signature lines.
        </div>

        <div className="signatures">
          <div className="group">
            <div className="signature-container">
              <div className="label customer">
                OSR: ____________________________
              </div>
            </div>
            <div className="signature-container">
              <div className="label date">
                Date: ____________________________
              </div>
            </div>
          </div>

          <div className="group">
            <div className="signature-container">
              <div className="label bhi">BHI: ____________________________</div>
            </div>
            <div className="signature-container">
              <div className="label date">
                Date: ____________________________
              </div>
            </div>
          </div>
        </div>
      </div>

      {!dailyTicket.isDailyTicket &&
      (checkboxValues.foremanJournal ||
        checkboxValues.forecastWork ||
        checkboxValues.issues ||
        (checkboxValues.changes && parData.statusComments?.length > 0)) ? (
        <div className="confidential-msg">
          * Confidential: Intended For Internal Use Only *
        </div>
      ) : (
        ""
      )}

      <div className={"buttons-wrapper"}>
        <div className="break-wrapper">
          <div className="checkbox" />
        </div>

        <div className="buttons">
          <button className="print" onClick={handleSubmit}>
            Print
          </button>

          <Link
            className="cancel"
            to={{
              pathname: `${prevPath}`,
              state: { approvalsCheckmarks: approvalsCheckmarks },
            }}
          >
            Cancel
          </Link>
        </div>
      </div>

      <ConfirmationModal
        isModalOpen={isModalOpen.dailyTicket}
        onRequestClose={() => handleCloseModal("dailyTicket")}
        modalQuestion={
          "Warning: You are PRINTING internal information, not intended for externally sharing."
        }
        onConfirm={handlePrint}
        yesButtonText={"Print"}
        noButtonText={"Cancel"}
      />
    </div>
  );
};

export default SelectPrintPage;
