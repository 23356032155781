import { useState, useEffect } from "react";

import GeneralModalLayout from "../modals/GeneralModalLayout";
import EmployeeItem from "./EmployeeItem";

import useDebounce from "../hooks/useDebounce";
import useAbortEffect from "../hooks/useAbortEffect";
import { useCurrentPar } from "../contexts/CurrentParContext";

import asyncAPICall from "../../util/apiWrapper";

const AddEmployee = (props) => {
  const {
    phaseNum,
    allPhaseEmployees,
    setAllPhaseEmployees,
    prevAllPhaseEmployees,
    closeModal,
  } = props;

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [toRemove, setToRemove] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { parFormDataState, parDispatch } = useCurrentPar();

  const debounceValue = useDebounce(searchValue);

  const submit = () => {
    closeModal();

    const removal = [];
    prevAllPhaseEmployees?.forEach((employee) => {
      if (!allPhaseEmployees.includes(employee)) {
        removal.push(employee.id);
      }
    });
    setToRemove(removal);

    if (!phaseNum) {
      parDispatch({
        type: "addRecords",
        attributeName: "laborData",
        payload: allPhaseEmployees,
      });
    } else {
      Object.keys(parFormDataState.phaseCodes).forEach((phaseCode) => {
        const checkedSelectedData = [];
        const employeeIDList = [];
        const payload = {
          phaseCode: phaseCode,
          attributeName: "laborData",
        };

        if (phaseCode === phaseNum || phaseCode === "012130-00-00") {
          parFormDataState.phaseCodes[phaseCode].laborData.forEach(
            (employee) => {
              employeeIDList.push(employee.id);
            }
          );

          selectedData.forEach((employeeData) => {
            const employeeDataRecord = {
              id: employeeData.id,
              name: employeeData.name,
              laborNotes: employeeData.laborNotes,
            };
            if (!employeeIDList.includes(employeeData.id)) {
              if (phaseCode === "012130-00-00") {
                employeeDataRecord.perDiem = employeeData.perDiem;
                employeeDataRecord.perDiemAmount = "";
                employeeDataRecord.combinedPhaseTime =
                  employeeData.combinedPhaseTime;
              } else if (parFormDataState.jobCertified === "Y") {
                employeeDataRecord.hoursWorked = employeeData.hoursWorked;
                employeeDataRecord.classes = {};
              } else {
                employeeDataRecord.hoursWorked = employeeData.hoursWorked;
              }

              checkedSelectedData.push(employeeDataRecord);
            }
          });

          payload.newRecords = checkedSelectedData;

          parDispatch({
            type: "addRecord",
            payload: payload,
          });
        }
      });
      setSelectedData([]);
    }
  };

  const renderData = () => {
    const idList = [];
    parFormDataState.phaseCodes[phaseNum]?.laborData.forEach((record) =>
      idList.push(record.id)
    );

    return filter.map((p) => {
      const selected = selectedData.some(
        (selectedRecord) => selectedRecord.id === p.employeeID
      );

      if (!idList.includes(p.employeeID)) {
        return (
          <EmployeeItem
            selectedEmployee={selected}
            data={p}
            key={p.employeeID}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            allPhaseEmployees={allPhaseEmployees}
            setAllPhaseEmployees={setAllPhaseEmployees}
            perDiem={props.perDiem}
            perDiemAmount={props.perDiemAmount}
            phaseNum={props.phaseNum}
          />
        );
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    if (toRemove.length > 0) {
      parDispatch({
        type: "removeFromAllPhases",
        attributeName: "laborData",
        payload: toRemove,
      });
    }
  }, [toRemove, parDispatch]);

  useAbortEffect((signal) => {
    function getEmployees() {
      asyncAPICall(
        "api:KE7MCNYf/vemployeesnospaces",
        "GET",
        null,
        null,
        (data) => {
          if (data) {
            setData(data.resource);
            setFilter(data.resource);
            setIsLoading(false);
          }
        },
        (err) => {
          if (!signal.aborted) {
            console.log("Getting employees Error", err);
          }
        },
        signal
      );
    }
    getEmployees();
  }, []);

  useEffect(() => {
    setFilter(
      data.filter(
        (employeeObject) =>
          employeeObject.LastName.toLowerCase().includes(
            debounceValue.toLowerCase()
          ) ||
          employeeObject.FirstName.toLowerCase().includes(
            debounceValue.toLowerCase()
          )
      )
    );
  }, [debounceValue, data]);

  return (
    <GeneralModalLayout
      title={"Select Employees"}
      isLoading={isLoading}
      renderModalInfo={renderData}
      handleSubmit={submit}
      setSearchValue={setSearchValue}
    />
  );
};

export default AddEmployee;
